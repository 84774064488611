import service from "../utils/request";
import Qs from "qs";
export function send(data) {
  return service({
    url: "/api/openai/send",
    method: "post",
    data,
  });
}

export function sse() {
  return fetch("http://localhost:8800/createSse", {
    method: "POST",
    headers: {
      Accept: "text/event-stream",
      //   "Content-Type": "aplication/json;charset=utf-8",
    },
    responseType: "text/event-stream",
  });
  //   return service({
  //     url: "http://localhost:9988/createSse",
  //     method: "POST",
  //     headers: {
  //       Accept: "text/event-stream",
  //       "Content-Type": "aplication/json;charset=utf-8",
  //     },
  //     responseType: "text/event-stream",
  //   });
}

export function seeChat(text) {
  return fetch("https://api.openai.com/v1/completions", {
    method: "POST",
    headers: {
      // Accept: "text/event-stream",
      "Content-Type": "application/json",
      Authorization: "",
    },
    body: JSON.stringify({
      model: "text-davinci-003",
      prompt: text,
      temperature: 0.9,
      max_tokens: 1024,
      top_p: 1,
      stream: true,
      frequency_penalty: 0.0,
      presence_penalty: 0.6,
      stop: [" Human:", " AI:"],
    }),
    responseType: "text/event-stream",
  });
}

export function seeSend(data, config = {}) {
  return fetch(process.env.VUE_APP_BASE_API + "/api/openai/sendStream", {
    ...config,
    method: "POST",
    headers: {
      // Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    responseType: "text/event-stream",
  });
}

export function seeSendNew(data, config = {}) {
  return fetch(process.env.VUE_APP_BASE_API + "/api/openai/sendStreamLimit", {
    ...config,
    method: "POST",
    headers: {
      // Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    responseType: "text/event-stream",
  });
}

export function sendStreamByLimit(data, config = {}) {
  return fetch(
    process.env.VUE_APP_BASE_API + "/api/userLogin/sendStreamByLimit",
    {
      ...config,
      method: "POST",
      headers: {
        // Accept: "text/event-stream",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      responseType: "text/event-stream",
    }
  );
}

export function textToImage(data) {
  return service({
    url: "/api/image/create",
    method: "post",
    data,
  });
}

export function textToImageSpeed(data) {
  return service({
    url: "/api/image/speed",
    method: "post",
    params: data,
  });
}

export function textToImageWaitNum() {
  return service({
    url: "/api/image/waitNum",
    method: "post",
  });
}

export function textToImageList(data) {
  return service({
    url: "/api/image/getImageList",
    method: "post",
    data,
  });
}

export function getUpdate(data) {
  return fetch(process.env.VUE_APP_BASE_API + "/api/image/getImageList", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
      pageNumber: 1,
      pageSize: 3,
    }),
  });
}

export function textToImageDel(data) {
  return service({
    url: "/api/image/deleteImg",
    method: "post",
    data,
  });
}

export function notice(data) {
  return fetch(process.env.VUE_APP_BASE_API + "/api/pay/getNotice", {
    method: "GET",
  }).then((res) => res.json());
}
export function textToNewImage(data) {
  return service({
    url: "http://192.168.21.3:8181/api/image/createImg",
    method: "post",
    data,
  });
}
export function textToNewImgList(data) {
  return service({
    url: "http://192.168.21.3:8181/api/image/getImgList",
    method: "post",
    data,
  });
}
