<template>
  <div class="textimg_detail">
    <div class="header">
      <div class="back" @click="$router.push('/drawImg')">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div>我的画册</div>
      <div class="edit" @click="toogleEdit">
        <i v-show="!editMode" class="el-icon-delete"></i>
        <span v-show="editMode" style="font-size: 16px; font-weight: normal">
          取消
        </span>
      </div>
    </div>
    <div class="section" ref="scrollview">
      <pull-refresh
        style="min-height: 100%"
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <!-- <list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        > -->
        <el-checkbox-group :disabled="!editMode" v-model="checkedDelIds">
          <ul class="task_list">
            <li
              class="task_item"
              :class="{
                edit: editMode,
              }"
              v-for="item in list"
              :key="item.id"
              @click="showImage(item)"
            >
              <div class="task_img_wrap">
                <template v-if="item.status == 0">
                  <el-checkbox :value="item.id" :label="item.id">
                    <div
                      class="task_img"
                      style="
                        background-color: #1c81f5;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        align-items: center;
                      "
                    >
                      正在绘制<br />
                      ……
                    </div>
                  </el-checkbox>
                </template>
                <template v-else-if="item.status == 1">
                  <el-checkbox :value="item.id" :label="item.id">
                    <el-image
                      class="task_img"
                      lazy
                      :src="baseImgUrl + '/ai_image' + item.imgUrl"
                      fit="cover"
                    ></el-image
                  ></el-checkbox>
                </template>
                <template v-else-if="item.status == 2">
                  <el-checkbox :value="item.id" :label="item.id">
                    <div
                      class="task_img"
                      style="
                        background-color: #666666;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        align-items: center;
                      "
                    >
                      生成失败
                    </div>
                  </el-checkbox>
                </template>
                <template v-else-if="item.status == 3">
                  <el-checkbox :value="item.id" :label="item.id">
                    <div
                      class="task_img"
                      style="
                        background-color: #1c81f5;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        align-items: center;
                      "
                    >
                      正在生成<br />
                      ……
                    </div>
                  </el-checkbox>
                </template>
              </div>
            </li>
          </ul>
        </el-checkbox-group>
        <!-- </list> -->
      </pull-refresh>
    </div>
    <div v-show="false">
      <img v-if="images" ref="images" :src="images" />
    </div>
    <el-button v-show="downloadShow" class="img_Btn" @click="downloadImg">
      <i class="el-icon-download"></i>
      下载
    </el-button>

    <el-button
      v-show="editMode && checkedDelIds.length"
      class="delete_btn"
      @click="deleteIamge"
    >
      <i class="el-icon-delete"></i>
      删除
    </el-button>
  </div>
</template>
<script>
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
// import { component as Viewer } from "v-viewer";
import { getToken, getUserId, removeAllAuth } from "@/utils/auth";
import {
  textToImageList,
  textToNewImgList,
  getUpdate,
  textToImageDel,
} from "@/api/home";
import PullRefresh from "vant/lib/pull-refresh";
import "vant/lib/pull-refresh/style";
import List from "vant/lib/list";
import "vant/lib/list/style";
export default {
  components: {
    PullRefresh,
    List,
  },
  props: {},
  data() {
    return {
      dialogVisible: false,
      downloadShow: false,
      images: "",
      options: {
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
      },
      checkedDelIds: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      status: false,
      editMode: false,
    };
  },
  beforeRouteEnter(to, form, next) {
    if (getToken() || getUserId()) {
      next();
    } else {
      removeAllAuth();
      next("/login");
    }
  },
  computed: {
    baseImgUrl() {
      return process.env.VUE_APP_BASE_IMG_URL;
    },
  },
  watch: {
    status(val, oldVal) {
      // if (oldVal == false && val == true) {
      console.log("有更新", val, oldVal);
      this.list = [];
      this.pageNumber = 1;
      this.onRefresh();
      // }
    },
  },
  created() {
    this.onRefresh();
    this._setInt && clearInterval(this._setInt);
    this._setInt = setInterval(() => {
      this.update();
    }, 4000);
  },
  mounted() {},
  beforeDestroy() {
    this._setInt && clearInterval(this._setInt);
  },
  methods: {
    toogleEdit() {
      this.editMode = !this.editMode;
      if (!this.editMode) {
        this.checkedDelIds = [];
      }
    },
    deleteIamge() {
      textToImageDel({
        ids: this.checkedDelIds,
        token: getToken(),
      }).then((res) => {
        this.onRefresh();
        this.checkedDelIds = [];
        this.editMode = false;
      });
    },
    onLoad() {
      textToImageList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        token: getToken(),
      }).then((res) => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        const data = res.data;
        // this.pageNumber += 1;
        this.list = data.data;
        // this.total = data.total;
        this.loading = false;
        // if (this.list.length >= this.total) {
        //   this.finished = true;
        // }
        try {
          setHasNot(this.list);
        } catch (error) {}
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    setHasNot(list) {
      if (list.length) {
        // 有绘制中或正在绘制
        this.status = list.some((item) => item.status == 0 || item.status == 3);
      } else {
        this.status = false;
      }
    },
    update() {
      getUpdate({
        token: getToken(),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.data) {
            this.setHasNot(res.data);
          } else {
          }
        });
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    getList() {},
    showImage(item) {
      if (this.editMode) {
        return;
      }
      if (item.status !== 1) {
        return;
      }
      console.log(item);
      console.log(this.$refs.images);
      this.images = "";
      this._viewer && this._viewer.destroy();
      this.images = this.baseImgUrl + "/ai_image" + item.imgUrl;
      console.log(
        "http://58.213.47.166:10004/ai_image/tmp/tmpy7vtj3tf/tmponsk19pw.png"
      );
      console.log(this.images);
      this.$nextTick(() => {
        this._viewer = new Viewer(this.$refs.images, {
          navbar: false,
          title: false,
          toolbar: false,
          tooltip: false,
          show: () => {
            console.log("show");
            this.downloadShow = true;
          },
          hide: () => {
            console.log("hide");
            this.downloadShow = false;
          },
        }).show();
      });
    },
    downloadImg() {
      this.downLoadImage(this.images);
    },
    downLoadImage(url) {
      let x = new XMLHttpRequest();
      let resourceUrl = url;
      let name = url.split("/").slice(-1)[0].split(".")[0];

      x.open("GET", resourceUrl, true);
      x.responseType = "blob";
      x.onload = (e) => {
        // ie10+
        if (navigator.msSaveBlob) {
          let name = resourceUrl.substr(resourceUrl.lastIndexOf("/") + 1);
          return navigator.msSaveBlob(x.response, name);
        } else {
          let url = window.URL.createObjectURL(x.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = name; //保存的文件名称
          a.click();
        }
      };
      x.send();
    },
  },
};
</script>
<style lang="scss" scoped>
.textimg_detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .section {
    flex: 1;
    overflow-y: auto;
    // display: flex;
    // justify-content: center;
  }
  .img_Btn {
    position: fixed;
    z-index: 9999999;
    bottom: 10%;
    width: 120px;
    height: 40px;
    // line-height: 40px;
    background-color: #fff !important;
    color: #1c81f5 !important;
    border: 0;
    font-size: 18px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .delete_btn {
    position: fixed;
    z-index: 100;
    bottom: 5%;
    width: 120px;
    height: 40px;
    // line-height: 40px;
    background-color: #ed4860 !important;
    color: #fff !important;
    border: 0;
    font-size: 16px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.header {
  height: 70px;
  background-color: #fff;
  position: relative;
  line-height: 70px;
  text-align: center;
  color: #7c7c7c;
  .back {
    font-size: 28px;
    font-weight: bolder;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }
  .edit {
    font-size: 24px;
    font-weight: bolder;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }
}

.task_list {
  display: flex;
  flex-flow: row wrap;
  list-style: none;

  ::v-deep.el-checkbox.is-disabled {
    .task_item {
      .el-checkbox {
      }
    }
  }
  .task_item {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin: 5px;
    font-size: 16px;
    width: 200px;
    ::v-deep .el-checkbox {
      position: absolute;
      width: 100%;
      height: 100%;
      .el-checkbox__input {
        &.is-disabled {
          display: none;
        }
      }
      .el-checkbox__label {
        cursor: pointer;
      }
      .el-checkbox__input {
        position: absolute;
        left: 28px;
        top: 23px;
      }
    }
    .task_img_wrap {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      .task_img {
        width: 200px;
        height: 200px;
        margin: 10px;
        border-radius: 6px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .task_item {
      width: 30%;
      margin: 1%;
      // width: 200px;
      ::v-deep .el-checkbox {
        .el-checkbox__input {
          position: absolute;
          left: 13%;
          top: 14%;
        }
      }
      .task_img_wrap {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        .task_img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
