<template>
  <div class="error">
    <el-empty
      :image="status == 500 ? image500 : image404"
      :description="
        status == 500
          ? '抱歉，服务器好像问了点小问题！'
          : '抱歉，您访问的页面不存在！'
      "
    ></el-empty>
    <el-button type="primary" class="refresh" @click="refresh">重试</el-button>
  </div>
</template>
<script>
import image404 from "../assets/images/404.png";
import image500 from "../assets/images/500.png";
export default {
  name: "Error",
  data() {
    return {
      image404,
      image500,
      status: 0,
    };
  },
  methods: {
    refresh() {
        this.$router.push('/home')
    }
  },
  created() {
    this.status = this.$route.query.status;
  },
};
</script>
<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .refresh {
    width: 160px;
    font-size: 18px;
    line-height: 25px;
  }
}
</style>
