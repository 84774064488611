// 导入自己需要的组件
import {
  Input,
  Button,
  Avatar,
  Empty,
  Link,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Message,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  Image,
  Progress,
  Loading,
  Tabs,
  TabPane,
  Popover
} from "element-ui";
const element = {
  install: function (Vue) {
    Vue.use(Input);
    Vue.use(Button);
    Vue.use(Avatar);
    Vue.use(Empty);
    Vue.use(Checkbox);
    Vue.use(Link);
    Vue.use(Dialog);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(CheckboxGroup);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Image);
    Vue.use(Progress);
    Vue.use(Loading);
    Vue.use(Popover);
    Vue.prototype.$message = Message;
  },
};
export default element;
