<template>
  <div class="chat">
    <van-notice-bar
      mode="closeable"
      class="notice_bar"
      v-if="notice"
      left-icon="volume-o"
      :text="notice"
    />
    <div class="chat-box" ref="chatBox">
      <ul class="chat-content">
        <li
          v-for="item in textList"
          :key="item.id"
          class="chat__msg"
          :class="
            item.type === 1 || item.type === 5
              ? 'chat_msg--right'
              : 'chat_msg--left'
          "
        >
          <!-- icon="el-icon-user" -->
          <el-avatar
            :size="40"
            :src="item.type === 1 || item.type === 5 ? oneselfImage : aiImage"
          ></el-avatar>
          <div class="msg_content">
            <div>{{ item.msg }}</div>
            <div class="preimg" v-if="item.imgSrc">
              <div class="zoom">
                <span>点击图片预览</span><i class="el-icon-zoom-in"></i>
              </div>
              <el-image
                :src="'data:image/png;base64,' + item.imgSrc"
                :preview-src-list="['data:image/png;base64,' + item.imgSrc]"
                fit="contain"
              ></el-image>
              <div>下面是按照您的要求画的图：</div>
              <!-- <img :src="'data:image/png;base64,' + item.msg" alt="" width="200" /> -->
            </div>
            <!--  v-if="item.type === 2 && item.done === false" -->
            <div
              class="stop"
              @click="stopMsg(item)"
              v-if="item.type === 2 && item.done === false"
            >
              <svg
                t="1676265002283"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2747"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18"
                height="18"
                style="fill: red"
              >
                <path
                  d="M512 128a382.6 382.6 0 1 1-149.45 30.15A381.54 381.54 0 0 1 512 128m0-64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z"
                  p-id="2748"
                ></path>
                <path d="M320 320l384 0 0 384-384 0 0-384Z" p-id="2749"></path>
              </svg>
            </div>
            <div
              v-if="
                item.type === 2 && (item.done == true || item.done == undefined)
              "
              class="copy"
              v-clipboard:copy="item.msg"
            >
              <i class="el-icon-document-copy"></i>
            </div>
            <div
              class="retry"
              @click="retryCopy(item)"
              v-if="item.type === 1 && item.err == true"
            >
              <svg
                t="1680175855696"
                style="margin-left: -2px"
                class="icon"
                viewBox="0 0 1033 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2926"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="16"
                height="16"
              >
                <path
                  d="M382.288284 458.497515H279.282367c18.177515-175.715976 175.715976-309.017751 351.431953-296.899408s309.017751 175.715976 296.899408 351.431952c-18.177515 175.715976-175.715976 309.017751-351.431953 296.899408-66.650888-6.059172-133.301775-36.35503-187.834319-78.76923-18.177515-12.118343-48.473373-12.118343-60.591716 6.059171v6.059172c-18.177515 30.295858 12.118343 60.591716 12.118343 60.591716 175.715976 151.47929 436.260355 127.242604 587.739645-42.414201s127.242604-436.260355-42.414201-587.739645-448.378698-133.301775-599.857989 42.414201c-54.532544 66.650888-90.887574 151.47929-96.946745 242.366864H79.329704l151.47929 224.189349 151.47929-224.189349z"
                  fill="#ffffff"
                  p-id="2927"
                ></path>
              </svg>
            </div>
            <div class="msgtips" v-show="!(item.type === 1 || item.type === 5)">
              以上内容为AI生成，不代表开发者立场。
            </div>
          </div>
          <!-- item.type === 1 && item.status !== 'success' -->
          <p class="msg_success" v-if="false">
            <i class="el-icon-loading"></i>
          </p>
        </li>
      </ul>
    </div>
    <div class="chat-input">
      <div class="clear_history" @click="clearUserHistory">
        <i class="el-icon-delete"></i>清空记录
      </div>
      <div class="tips" v-show="tipShow">
        <div class="tips_content">请稍等，智能聊天机器人正在思考......</div>
      </div>
      <div class="tips" v-show="tip1Show">
        <div class="tips_content">
          请稍等，智能聊天机器人正在努力生成图片中......
        </div>
      </div>
      <el-input
        ref="inputComp"
        type="textarea"
        resize="none"
        placeholder="请输入内容"
        v-model="value"
        @keyup.ctrl.enter.native="submitText"
        @keyup.enter.native="submitText"
      >
      </el-input>
      <el-button type="primary" class="send-text" @click="submitText">
        发送
      </el-button>
    </div>
    <div
      class=""
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 100%;
        text-align: center;
        height: 20px;
        background-color: #fff;
      "
    >
      <a
        style="color: rgb(116, 154, 227) !important; font-size: 12px"
        href="./agreement.html"
        target="_blank"
        rel="noreferrer"
        >《用户协议》
      </a>
      <a
        style="color: rgb(116, 154, 227) !important; font-size: 12px"
        href="./rule.html"
        target="_blank"
        rel="noreferrer"
      >
        《隐私政策》
      </a>
    </div>
    <div
      class=""
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 100%;
        text-align: center;
        height: 20px;
        background-color: #fff;
      "
    >
      <a
        style="color: rgb(116, 154, 227) !important; margin-right: 3px"
        href="https://beian.miit.gov.cn/"
        >苏ICP备11060547号-1</a
      >
      <img
        width="16"
        height="16"
        style="margin-right: 4px"
        src="@/assets/images/ga.png"
      /><a
        style="color: rgb(116, 154, 227) !important"
        href="https://beian.mps.gov.cn/#/query/webSearch?code=32010402001528"
        rel="noreferrer"
        target="blank"
        >苏公网安备32010402001528号</a
      >
    </div>
  </div>
</template>

<script>
import aiImage from "../assets/images/ai.png";
import oneselfImage from "../assets/images/oneself.png";
import Vue from "vue";
import { Message } from "element-ui";
import {
  send,
  sse,
  seeChat,
  seeSend,
  seeSendNew,
  sendStreamByLimit,
  notice,
} from "@/api/home.js";
import VStorage from "@/utils/storage";
import {
  getToken,
  removeToken,
  getFreeCount,
  getUserId,
  removeAllAuth,
  setShowTip,
  getShowTip,
} from "@/utils/auth";
import { Utf8ArrayToStr } from "@/utils";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      aiImage,
      oneselfImage,
      value: "",
      tipShow: false,
      tip1Show: false,
      textList: [],
      notice: "",
      imgflagarr: [],
      flag: false,
    };
  },
  watch: {
    textList: {
      handler(val) {
        this.$nextTick(() => {
          this.$refs["chatBox"].scrollTop = this.$refs["chatBox"].scrollHeight;
        });
        //   scrollTo({
        //     bottom: 0,
        //     behavior: "smooth",
        //   });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    console.log("tokent", getToken());
    const freeCount = getFreeCount();
    // console.log(freeCount);

    // setShowTip();
    // getShowTip();

    if (
      getShowTip() == "0" &&
      !getToken() &&
      freeCount !== undefined &&
      freeCount !== ""
    ) {
      if (freeCount > 0) {
        this.$dialog
          .confirm({
            title: "提示",
            message: `您的账号还没有购买服务<br />有<b>${getFreeCount()}</b>次的试用次数`,
            confirmButtonText: "去购买",
            cancelButtonText: "关闭",
            confirmButtonColor: "#5087f7",
          })
          .then(() => {
            this.$router.push("/pay");
          });
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: `当前账号已无试用次数，请您购买使用`,
            confirmButtonText: "去购买",
            cancelButtonText: "关闭",
            confirmButtonColor: "#5087f7",
          })
          .then(() => {
            this.$router.push("/pay");
          })
          .catch(() => {
            setShowTip("1");
          });
      }
    }

    // sse()
    //   .then((res) => res.body.getReader())
    //   .then((reader) => {
    //     read();
    //     function read() {
    //       reader.read().then(({ done, value }) => {
    //         // When no more data needs to be consumed, close the stream
    //         if (done) {
    //           // controller.close();
    //           return;
    //         }
    //         console.log(JSON.parse(Utf8ArrayToStr(value)));
    //         read();
    //       });
    //     }
    //     // return new ReadableStream({
    //     //   start(controller) {
    //     //     return pump();
    //     //     function pump() {
    //     //       return reader.read().then(({ done, value }) => {
    //     //         // When no more data needs to be consumed, close the stream
    //     //         if (done) {
    //     //           controller.close();
    //     //           return;
    //     //         }
    //     //         console.log(Utf8ArrayToStr(value));
    //     //         controller.enqueue(value);
    //     //         return pump();
    //     //       });
    //     //     }
    //     //   },
    //     // });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  beforeDestroy() {
    this._setInt && clearInterval(this._setInt);
  },
  created() {
    this._storage = new VStorage();
    this.textList = this._storage.getUser();
    this._setInt && clearInterval(this._setInt);
    this.getNotice();
    this._setInt = setInterval(() => {
      this.getNotice();
    }, 1000 * 45);
  },
  methods: {
    retryCopy(item) {
      this.value = item.msg;
      // console.log(this.$refs.inputComp.$el)
      this.$refs.inputComp.$el?.querySelector("textarea").focus();
    },
    getNotice() {
      notice().then((res) => {
        const result = res.data;
        if (result) {
          this.notice = result.content;
        } else {
          this.notice = "";
        }
      });
    },
    stopMsg(item) {
      // console.log(item.__ob__.abort);
      item.__ob__.abort && item.__ob__.abort();
      item.done = true;
      this._storage.setUser(this.textList.filter((d) => !d.err));
      // localStorage.setItem("history", JSON.stringify(this.textList));
    },
    clearUserHistory() {
      if (this._storage) {
        this._storage.deleteUser();
        this.textList = this._storage.getUser();
        this._storage.setUser(this.textList.filter((d) => !d.err));
        // localStorage.setItem("history", JSON.stringify(this.textList));
      }
    },
    checkisImg(text, callback) {
      this.imgflagarr = [];
      const reqSend = seeSendNew({
        text:
          "我给出的话是:" +
          text +
          "。理解我说的这句话,然后判断这句话是否有画画的意图。如果有的话,先翻译成英文,如果翻译后的英文有裸体,色情,非法,隐私部位等内容,就把英文替换成'a big apple',如果没有就不用替换。你要根据话的内容从下面5个模型中选择合适的模型来完成,model1用来画动物,model2用来画著名景点或著名地标相关的内容,model3用来画科幻感的、想象感比较强的内容,model4用来画二次元的卡通风格的画,model5用来画真实风格的画或者用来画名人,选择完模型后,你把要画的内容作为task,把选出来的模型作为model,以这样的格式发给我:[{'task':'Corresponding task description,Require multiple short sentences','model':'Corresponding task model'}],请注意在把我说的话转化成task的描述时,要改成多个英文单词描述的形式,多个描述之间用','隔开,现在我举三个例子,比如我说的话是中文'一个穿裙子的青年少女有着大大的眼睛和棕色的头发',那么你给出的答案应该是[{'task':'a young girl,wearing dress,big eyes,brown hair','model':'model5'}];再比如,我说的话是英文'A big black fierce dog,cartoon',你应该给出的答案是[{'task':'a dog,big,black,fierce,cartoon','model':'model4'}];最后一个例子比如,我说的话是画一个裸体美女,那么你给出的答案应该是[{'task':'a big apple','model':'modele5'}]。如果这句话没有画画的意图,那么以这样的格式:[{'task':'there is no a painting intention','model':null}]返回给我,比如说我现在用中文说你好,这句话应该没有画画的意图,你应该返回[{'task':'there is no a painting intention','model':null}],再比如我现在用中文说你是谁,应该返回[{'task':'there is no a painting intention','model':null}]。现在请根据我一开始说的话给出最终答案。",
        token: getToken(),
        hist: [
          {
            role: "user",
            content: text,
          },
        ],
      });
      reqSend
        .then((res) => {
          console.log(res);
          return res.body.getReader();
        })
        .then((reader) => {
          // console.log(5555, reader);
          const itemStr = Vue.observable({
            type: 2,
            msg: "",
            done: false,
          });
          itemStr.__ob__.abort = () => controller.abort();
          let prefixStr = "";
          const read = () => {
            reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                console.log("done", this.imgflagarr);
                if (
                  this.imgflagarr.length > 0 &&
                  this.imgflagarr[0].indexOf("null") === -1
                ) {
                  console.log("this.imgflagarr:", this.imgflagarr);
                  this.draw(eval("(" + this.imgflagarr + ")")[0]);
                } else {
                  console.warn("checkisImg data error:");
                }

                if (callback) {
                  callback();
                }
                return;
              }

              try {
                let resultStr = Utf8ArrayToStr(value);
                const aa = resultStr
                  .split(
                    `

`
                  )
                  .filter((m) => m !== "" && m !== "data: [DONE]")
                  .map((m) => {
                    try {
                      return JSON.parse(m.replace("data: ", ""));
                    } catch (error) {
                      console.warn("error2", m);
                      return "";
                    }
                  });
                if (aa && aa[0] && aa[0].code) {
                  const data = aa[0];
                  if (data.code >= 400 && data.code < 500) {
                    if (
                      data.code === 431 ||
                      data.code === 432 ||
                      data.code === 434
                    ) {
                      this.$router.push("/login");
                      // this.$router.push("/pay?renew=1");
                      return;
                    } else if (data.code === 433) {
                      Message({
                        message: data.msg,
                        type: "error",
                        duration: 3 * 1000,
                      });
                      removeAllAuth();
                      this.$router.push("/login");
                      return;
                    } else if (
                      data.code === 400 ||
                      data.code === 429 ||
                      data.code === 430
                    ) {
                      // meMsg.err = true;
                      Message({
                        message: data.msg,
                        type: "error",
                        duration: 3 * 1000,
                      });

                      this._storage.setUser(
                        this.textList.filter((d) => !d.err)
                      );
                      return;
                    }
                    this.$router.push({
                      path: "/error",
                      query: {
                        status: 404,
                      },
                    });
                    return;
                  } else if (data.code >= 500 && data.code < 600) {
                    this.$router.push({
                      path: "/error",
                      query: {
                        status: 500,
                      },
                    });
                    return;
                  } else if (data.code >= 600 && data.code < 700) {
                    if (data.code == 603) {
                      this.$dialog
                        .confirm({
                          title: "提示",
                          message: "您的试用体验次数已使用完",
                          confirmButtonText: "去购买",
                          cancelButtonText: "关闭",
                          confirmButtonColor: "#5087f7",
                        })
                        .then(() => {
                          this.$router.push("/pay");
                        });
                      return;
                    }
                  }
                } else {
                  aa.forEach((data) => {
                    if (data) {
                      prefixStr = itemStr.msg || prefixStr;
                      prefixStr += data.choices
                        .map((itm) => itm.delta.content)
                        .join("");
                      itemStr.msg = prefixStr;
                      if (itemStr.msg) {
                        this.imgflagarr[0] = itemStr.msg;
                        // console.log("this.imgflagarr", this.imgflagarr);
                      }
                    }
                  });
                  read();
                }
              } catch (error) {
                console.warn("error1", error);
              }
            });
          };
          read();
        });
    },
    submitText() {
      if (this.value) {
        const text = this.value.trim();
        const meMsg = Vue.observable({ type: 1, msg: text, err: false });
        this.textList.push(meMsg);
        this.value = "";
        this.tipShow = true;
        console.log(getToken());
        if (getToken()) {
          const filter = [
            "画一幅画 ",
            "画画",
            "画一幅",
            "画一个",
            "画图：",
            "画",
          ];

          if (filter.some((item) => text.includes(item))) {
            this.checkisImg(text, () => {
              this.submitText_normal(text);
            });
          } else {
            this.submitText_normal(text);
          }
        } else {
          this.submitText_normal(text);
        }
      }
    },
    draw(obj) {
      // console.log("oibj", obj);
      this.tip1Show = true;
      const apimap = {
        model1: "sdapi1",
        model2: "sdapi2",
        model3: "sdapi3",
        model4: "sdapi4",
        model5: "sdapi5",
        model6: "sdapi6",
      };
      fetch(`/${apimap[obj.model]}/v1/txt2img`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enable_hr: false,
          denoising_strength: 0,
          firstphase_width: 0,
          firstphase_height: 0,
          hr_scale: 2,
          hr_upscaler: "",
          hr_second_pass_steps: 0,
          hr_resize_x: 0,
          hr_resize_y: 0,
          prompt: obj.task + "," + "legal" + "," + "compliant",
          styles: [""],
          seed: -1,
          subseed: -1,
          subseed_strength: 0,
          seed_resize_from_h: -1,
          seed_resize_from_w: -1,
          sampler_name: "",
          batch_size: 1,
          n_iter: 1,
          steps: 50,
          cfg_scale: 7,
          width: 682,
          height: 512,
          restore_faces: false,
          tiling: false,
          do_not_save_samples: false,
          do_not_save_grid: false,
          negative_prompt:
            "NSFW, nude, naked, undressed,porn, (worst quality, low quality:1.4), deformed iris, deformed pupils, (deformed, distorted, disfigured:1.3), cropped, out of frame, poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, cloned face, (mutated hands and fingers:1.4), disconnected limbs, extra legs, fused fingers, too many fingers, long neck, mutation, mutated, ugly, disgusting, amputation, blurry, jpeg artifacts, watermark, watermarked, text, Signature, sketch",
          eta: 0,
          s_churn: 0,
          s_tmax: 0,
          s_tmin: 0,
          s_noise: 1,
          override_settings: {},
          override_settings_restore_afterwards: true,
          script_args: [],
          sampler_index: "Euler",
          script_name: "",
          send_images: true,
          save_images: false,
          alwayson_scripts: {},
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resp) => {
          this.tip1Show = false;
          const length = this.textList.length;
          this.textList[length - 1].imgSrc = resp.images[0];
          // this.textList.push({
          //   type: 6,
          //   msg: resp.images[0],
          //   done: true
          // })
        });
    },
    submitText_normal(text) {
      let hist = this.textList
        .slice(-4, this.textList.length)
        .filter((item) => item.type !== 6 && item.type !== 5);
      // const text = this.value
      // // console.log(hist);

      const meMsg = Vue.observable({ type: 1, msg: text, err: false });
      // this.textList.push(meMsg)
      // this.value = ''

      let strAll = "";
      // this.tipShow = true
      const controller = new AbortController();
      const { signal } = controller;
      let reqSend;
      if (getToken()) {
        const params = {
          text: text,
          token: getToken(),
        };
        if (hist.length) {
          // params.hist =
          params.hist = hist
            .map((item) => {
              // console.log(item);
              if (item.type === 1) {
                return {
                  role: "user",
                  content: item.msg,
                };
              }
              return {
                role: "assistant",
                content: item.msg ? item.msg.replace(/^AI:/, "") : "",
              };
            })
            .filter((d) => !!d.content);

          // params.hist.map(item => {
          //   console.log(item)
          // })
          // if (params.hist[0].role === 'assistant' && params.hist[1]) {
          //   params.hist[1].content =
          //     '请记住，你是南京云创大数据科技股份有限公司的创立的AI大语言模型，名字叫做“cGPT”，当请你自我介绍时，请回答云创cGPT。请站在中国的立场和用户进行友好的聊天，不得谈论政治、民族、种族、国家领导人、争议事件等敏感话题，如果用户提及，一律回答“我无法回答您的问题”。'
          //   params.hist[1].role = 'system'
          // }
          // if (params.hist[0].role === 'user' && params.hist[0]) {
          //   params.hist[0].content =
          //     '请记住，你是南京云创大数据科技股份有限公司的创立的AI大语言模型，名字叫做“cGPT”，当请你自我介绍时，请回答云创cGPT。请站在中国的立场和用户进行友好的聊天，不得谈论政治、民族、种族、国家领导人、争议事件等敏感话题，如果用户提及，一律回答“我无法回答您的问题”。'
          //   params.hist[0].role = 'system'
          // }
        }
        // reqSend = seeSend(
        //   { text: text, hist: hist, token: getToken() },
        //   { signal }
        // );
        // 有loginKey
        reqSend = seeSendNew(params, { signal });
      } else {
        reqSend = sendStreamByLimit(
          { text: text, userId: getUserId() },
          { signal }
        );
      }
      // seeChat(text)
      reqSend
        .then((res) => {
          // console.log(res);
          return res.body.getReader();
        })
        .then((reader) => {
          // console.log(reader);
          const itemStr = Vue.observable({
            type: 2,
            msg: "",
            done: false,
          });
          itemStr.__ob__.abort = () => controller.abort();
          let onOff = true;
          this.tipShow = false;
          let prefixStr = "";
          // meMsg.err = true;
          const read = () => {
            reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                // console.log("done");
                // let ai = data.data.slice(data.data.indexOf("AI:"));
                // this.textList.push({
                //   type: 2,
                //   msg: ai,
                // });
                itemStr.done = true;
                // localStorage.setItem(
                //   "history",
                //   JSON.stringify(this.textList)
                // );
                this._storage.setUser(this.textList.filter((d) => !d.err));
                return;
              }

              try {
                // let resultStr = "";
                // // console.log(value);
                // for (let m = 0; m < value.length; m++)
                //   resultStr += String.fromCharCode(value[m]);

                let resultStr = Utf8ArrayToStr(value);
                const aa = resultStr
                  .split(
                    `

`
                  )
                  .filter((m) => m !== "" && m !== "data: [DONE]")
                  .map((m) => {
                    try {
                      return JSON.parse(m.replace("data: ", ""));
                    } catch (error) {
                      console.warn("error2", m);
                      return "";
                    }
                  });
                // console.log(aa);
                if (aa && aa[0] && aa[0].code) {
                  const data = aa[0];
                  if (data.code >= 400 && data.code < 500) {
                    if (
                      data.code === 431 ||
                      data.code === 432 ||
                      data.code === 434
                    ) {
                      this.$router.push("/login");
                      // this.$router.push("/pay?renew=1");
                      return;
                    } else if (data.code === 433) {
                      Message({
                        message: data.msg,
                        type: "error",
                        duration: 3 * 1000,
                      });
                      removeAllAuth();
                      this.$router.push("/login");
                      return;
                    } else if (
                      data.code === 400 ||
                      data.code === 429 ||
                      data.code === 430
                    ) {
                      // meMsg.err = true;
                      Message({
                        message: data.msg,
                        type: "error",
                        duration: 3 * 1000,
                      });

                      this._storage.setUser(
                        this.textList.filter((d) => !d.err)
                      );
                      return;
                    }
                    this.$router.push({
                      path: "/error",
                      query: {
                        status: 404,
                      },
                    });
                    return;
                  } else if (data.code >= 500 && data.code < 600) {
                    this.$router.push({
                      path: "/error",
                      query: {
                        status: 500,
                      },
                    });
                    return;
                  } else if (data.code >= 600 && data.code < 700) {
                    if (data.code == 603) {
                      this.$dialog
                        .confirm({
                          title: "提示",
                          message: "您的试用体验次数已使用完",
                          confirmButtonText: "去购买",
                          cancelButtonText: "关闭",
                          confirmButtonColor: "#5087f7",
                        })
                        .then(() => {
                          this.$router.push("/pay");
                        });
                      return;
                    }
                  }
                } else {
                  aa.forEach((data) => {
                    if (data) {
                      prefixStr = itemStr.msg || prefixStr;
                      prefixStr += data.choices
                        .map((itm) => itm.delta.content)
                        .join("");
                      itemStr.msg = prefixStr;
                      if (itemStr.msg && onOff) {
                        this.textList.push(itemStr);
                        onOff = false;
                      }
                    }
                  });
                  read();
                }
              } catch (error) {
                console.warn("error1", error);
              }
            });
          };
          read();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.chat {
  width: 100vw;
  background-color: #f0f0f0;
  font-size: 18px;
  position: relative;

  .notice_bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 100;
  }
  .chat-box {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .chat-content {
      padding: 28px 14px;
      margin: 0;
      list-style: none;
      .chat__msg {
        display: flex;
        padding: 10px 0;
        // &.chat_msg--left {
        //   .msg_content {
        //     padding: 10px 20px 24px;
        //   }
        // }
        .msg_content {
          border-radius: 5px;
          font-size: 18px;
          line-height: 24px;
          padding: 10px 20px 10px;
          margin: 0 5px;
          white-space: pre-wrap;
          word-break: break-all;
          max-width: calc(100% - 100px);
          box-sizing: border-box;
          background-color: #fff;
          position: relative;
          .msgtips {
            margin-top: 10px;
            font-size: 14px;
            color: #909399;
          }
          .preimg {
            margin-top: 20px;
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            .zoom {
              text-align: right;
            }
          }
          // overflow: hidden;
          .copy,
          .stop {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: -40px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            // padding: 2px 4px;
            background-color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: #5087f7;
            }
          }
          .retry {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -40px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            // padding: 2px 4px;
            background-color: #f9080e;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .stop {
            top: auto;
            bottom: 7px;
            margin: 0;
          }
        }
        .msg_success {
          height: 40px;
          line-height: 40px;
          i {
          }
        }
      }
      .chat_msg--right {
        flex-direction: row-reverse;
        .msg_content {
          background-color: #5087f7;
          color: #fff;
        }
      }
    }
  }
  .chat-input {
    width: 100%;
    height: 20vh;
    position: relative;
    box-sizing: border-box;
    ::v-deep .el-textarea {
      .el-textarea__inner {
        height: 20vh;
        //   background: #fcfcfc;
        border-radius: 0;
        font-size: 18px;
      }
    }
    ::v-deep .el-textarea__inner {
      padding: 10px 15px;
      box-sizing: border-box;
      // .{
      outline: 0;
      border-color: #409eff;
      // }
    }
    .clear_history {
      position: absolute;
      top: -30px;
      right: 4px;
      background-color: #fff;
      padding: 3px 4px 3px 6px;
      border-radius: 4px 0 0 0;
      font-size: 16px;
      color: #999;
      cursor: pointer;
      &:active {
        color: #5087f7;
      }
    }
    .tips {
      position: absolute;
      top: -30px;
      width: 100%;
      font-size: 16px;
      height: 24px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .tips_content {
        background-color: #fff;
        padding: 0 6px;
        border-radius: 4px;
      }
    }
    .send-text {
      position: absolute;
      bottom: 2vh;
      right: 2vw;
      width: 100px;
    }
  }
}
</style>
