import Cookies from "js-cookie";

const TokenKey = "Admin-Token";
const LoginCode = "Login-Code";
const UserId = "UserId";
const FreeCount = "FreeCount";
const ShowTip = "ShowTip";

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 30 });
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function setLoginCode(key) {
  return Cookies.set(LoginCode, key, { expires: 30 });
}
export function getLoginCode() {
  return Cookies.get(LoginCode);
}
export function removeLoginCode() {
  return Cookies.remove(LoginCode);
}

export function setUserId(key) {
  return Cookies.set(UserId, key, { expires: 30 });
}
export function getUserId() {
  return Cookies.get(UserId);
}
export function removeUserId() {
  return Cookies.remove(UserId);
}

export function setFreeCount(key) {
  return Cookies.set(FreeCount, key, { expires: 30 });
}
export function getFreeCount() {
  return Cookies.get(FreeCount);
}
export function removeFreeCount() {
  return Cookies.remove(FreeCount);
}

export function setShowTip(key) {
  return Cookies.set(ShowTip, key, { expires: 30 });
}
export function getShowTip() {
  return Cookies.get(ShowTip) || '0';
}
export function removeShowTip() {
  return Cookies.remove(ShowTip);
}

export function removeAllAuth() {
  removeToken();
  removeLoginCode();
  removeUserId();
  // removeFreeCount();
  removeShowTip();
}
