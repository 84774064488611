import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import ChatView from "../views/ChatView.vue";
import ExpertView from "../views/ExpertView.vue";
import ErrorView from "../views/ErrorView.vue";
import TextToImgView from "../views/TextToImgView.vue";
import TextToImageDetail from "../views/TextToImageDetail.vue";
import PayView from "../views/PayView.vue";
import wxPayIframe from "../views/wxPayIframe.vue";
import RegisterView from "../views/RegisterView.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import DrawImgView from "../views/DrawImgView.vue";
Vue.use(VueRouter);

//添加以下代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/chat",
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/forgot",
    name: "forgot",
    component: ForgotPassword,
  },
  {
    path: "/toImgList",
    component: TextToImageDetail,
    name: "t2imglist",
  },
  {
    path: "/pay",
    component: PayView,
    name: "pay",
  },
  {
    path: "/wxIfa",
    component: wxPayIframe,
    name: "wxIfa",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    redirect: "chat",
    children: [
      {
        path: "/chat",
        component: ChatView,
        name: "chat",
      },
      {
        path: "/expert",
        component: ExpertView,
        name: "expert",
      },
      {
        path: "/toImg",
        component: TextToImgView,
        name: "t2img",
      },
      {
        path: "/drawImg",
        component: DrawImgView,
        name: "drawImg",
      },
      {
        path: "/error",
        component: ErrorView,
        name: "error",
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // console.log(to);
//   // && to.path === form.path
//   console.log(getToken());
//   // if (getToken()) {
//   //   return next("/home");
//   // }
//   next();
// });

export default router;
