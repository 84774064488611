import { render, staticRenderFns } from "./wxPayIframe.vue?vue&type=template&id=ac9344fc&scoped=true&"
import script from "./wxPayIframe.vue?vue&type=script&lang=js&"
export * from "./wxPayIframe.vue?vue&type=script&lang=js&"
import style0 from "./wxPayIframe.vue?vue&type=style&index=0&id=ac9344fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac9344fc",
  null
  
)

export default component.exports