import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "./utils/elementUI";
import VueClipboard from "vue-clipboard2";
import { Toast } from "vant";
import "vant/lib/toast/style";
import { Dialog, NoticeBar } from "vant";
import "vant/lib/dialog/style";
import "vant/lib/notice-bar/style";

Vue.use(NoticeBar);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
