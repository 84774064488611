<template>
  <div class="iframeview">
    <iframe v-if="url" class="iframe-dom" :src="url" frameborder="0"></iframe>
  </div>
</template>
<script>
import { getUserInfo } from "@/api/login";
import { getToken, setToken, getUserId, setLoginCode } from "@/utils/auth";
export default {
  components: {},
  props: {},
  data() {
    return {
      // url: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.url = this.$route.query.url;
    this.getLoginKey();
  },
  mounted() {},
  beforeDestroy() {
    this._setIntKey && clearInterval(this._setIntKey);
  },
  methods: {
    getLoginKey() {
      this._setIntKey && clearInterval(this._setIntKey);
      this._setIntKey = setInterval(() => {
        getUserInfo({
          userId: getUserId(),
        }).then((res) => {
          const result = res.data;
          if (result.code == 200) {
            if (result.data) {
              this.$toast.success("支付成功！");
              setToken(result.data);
              setTimeout(() => {
                this.$router.replace("/chat");
              }, 2000);
            }
          } else {
          }
        });
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
.iframeview {
  width: 100%;
  height: 100%;
  .iframe-dom {
    width: 100%;
    height: 100%;
  }
}
</style>
