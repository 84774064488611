<template>
  <div class="register">
    <template v-if="isFromPay">
      <div class="back" @click="$router.go(-1)">
        <i class="el-icon-arrow-left"></i>
        返回
      </div>
    </template>
    <template v-else>
      <div class="back" @click="$router.push('/login')">
        <i class="el-icon-arrow-left"></i>
        登录
      </div>
    </template>
    <div class="login-box">
      <img :src="aiImage" width="70px" />
      <h2>AI智能机器人</h2>
      <div>
        <!-- <p>注册</p> -->
        <el-input
          class="m_input"
          name="phone"
          style="margin-bottom: 16px"
          placeholder="手机号"
          v-model="phone"
          autocomplete="off"
        >
        </el-input>
        <div></div>
        <el-input
          class="m_input sms_code"
          style="margin-bottom: 16px"
          placeholder="验证码"
          v-model="smsCode"
          autocomplete="off"
        >
          <template slot="append">
            <div style="color: #cacfd9" v-show="countdown > 0">
              {{ countdown }}s
            </div>
            <div v-show="countdown <= 0" @click="getSmsCode">获取验证码</div>
          </template>
        </el-input>
        <div></div>
        <el-input
          class="m_input"
          placeholder="请输入密码"
          v-model="password"
          name="password"
          show-password
          autocomplete="off"
        ></el-input>
      </div>
      <div>
        <el-button
          class="register_btn"
          type="primary"
          :loading="registerLoading"
          :disabled="registerLoading"
          @click="submitLogin"
          >注册</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  getToken,
  setToken,
  setLoginCode,
  removeLoginCode,
  getShowTip,
  setShowTip,
  setUserId,
  setFreeCount,
  removeFreeCount,
  removeUserId,
} from "@/utils/auth";
import aiImage from "@/assets/images/login.png";
import Typed from "typed.js";
import { login, userRegister, getSMS, getUserInfo } from "@/api/login.js";

export default {
  name: "Login",
  data() {
    return {
      registerLoading: false,
      aiImage,
      phone: "",
      smsCode: "",
      password: "",
      countdown: 0,
      isFromPay: false,
    };
  },
  // beforeRouteEnter(to, form, next) {
  //   if (getToken()) {
  //     next("/home");
  //   } else {
  //     next();
  //   }
  // },
  created() {
    // console.log(this.$route.query);
    this.isFromPay = this.$route.query.from === "pay";
  },
  mounted() {},
  methods: {
    countDownFn() {
      this.countdown = 60;
      this._setInt && clearInterval(this._setInt);
      this._setInt = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(this._setInt);
        }
      }, 1000);
    },
    getSmsCode() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^1[3-9][0-9]{9}$/.test(this.phone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      getSMS({
        phone: this.phone,
      }).then((res) => {
        if (res.data.code !== 200) {
          this.$toast(res.data.msg);
          return;
        }
        this.countDownFn();
      });
    },

    async submitLogin() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.smsCode) {
        this.$toast("请输入手机验证码");
        return;
      }
      if (!this.password) {
        this.$toast("请输入密码");
        return;
      }
      this.registerLoading = true;
      userRegister({
        password: this.password,
        phoneNumber: this.phone,
        verificateCode: this.smsCode,
      })
        .then((res) => {
          console.log(res);
          const result = res.data;
          if (result.code == 200) {
            this.$toast.success("注册成功");
            setTimeout(() => {
              if (this.isFromPay) {
                removeLoginCode();
                // setShowTip("0");
                // setFreeCount(result.data.freeCount);
                setUserId(result.data.userId);
                this.$router.replace({
                  path: "/pay?renew=1",
                });
              } else {
                this.$router.replace({
                  path: "/login",
                  query: {
                    t: "2",
                  },
                });
              }
            }, 1000);
          } else {
            this.$toast(result.msg);
          }
        })
        .finally((err) => {
          this.registerLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #f6f7f9;
  overflow-y: auto;
  .back {
    font-size: 20px;
    position: fixed;
    font-weight: bold;
    left: 20px;
    top: 10px;
    // width: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5087f7;
    .el-icon-arrow-left {
      font-size: 28px;
    }
  }
  .m_input {
    width: 75vw;
    max-width: 320px;
  }

  ::v-deep .el-tabs__nav {
    // display: flex;
    width: 100%;

    .el-tabs__item {
      width: 50% !important;
      font-size: 20px;
      color: #84878d;

      &.is-active {
        color: #5087f7;
      }
    }
  }

  .el-tabs__nav-wrap::after {
    background-color: #c8cbd2 !important;
  }

  .help {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ccc;
    font-size: 14px;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 24px;
    }
  }

  .course_link {
    margin-top: 30px;

    .el-link {
      font-size: 18px;
      color: #409eff;
      position: relative;

      text-decoration: underline;
      // &::after {
      //   content: '';
      //   position: absolute;
      //   left: 0;
      //   right: 0;
      //   height: 0;
      //   bottom: 0;
      //   border-bottom: 1px solid #409EFF;
      // }
    }
  }

  .login-box {
    margin-top: -15%;
    text-align: center;
    padding: 0 20px;

    h2 {
      color: #5087f7;
      margin: 15px 30px 30px;
    }

    position: relative;

    .code_input {
      ::v-deep .el-input__inner {
        text-align: center;

        &::-webkit-input-placeholder {
          text-align: center;
        }
      }
    }

    ::v-deep .el-input__inner {
      height: 46px;
      line-height: 46px;
      font-size: 20px;
      // border-color: #409EFF;
      // outline: 0;

      outline: 0;
      border-color: #409eff;

      &::-webkit-input-placeholder {
        font-size: 24px;
      }
    }

    ::v-deep .sms_code {
      position: relative;

      .el-input-group__append {
        position: absolute;
        right: 1px;
        top: 1px;
        font-size: 16px;
        height: calc(100% - 4px);
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        color: #5087f7;
        background-color: transparent;
        cursor: pointer;
      }
    }

    ::v-deep .el-input {
      &.blur {
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 15px;
          top: 12px;
          height: 24px;
          width: 2px;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #333;
          animation: blink 1s infinite;
        }
      }

      &.focus {
        &::after {
          content: "";
          display: none;
        }
      }
    }

    ::v-deep .el-button {
      font-size: 24px;
      margin-top: 30px;
    }

    .checkbox-read {
      margin-top: 50px;

      ::v-deep .el-checkbox {
        vertical-align: middle;

        .el-checkbox__inner {
          width: 18px;
          height: 18px;
        }

        .el-checkbox__inner::after {
          height: 12px;
          left: 6px;
        }
      }

      .message {
        font-size: 16px;
        color: #cfd2db;
        margin-left: 10px;
      }
    }
  }
}

::v-deep .declaration {
  .el-dialog__body {
    padding: 5px 20px 30px;

    p {
      text-indent: 2em;
    }
  }
}
</style>
