<template>
  <div class="login">
    <div class="help" v-if="false">
      <i class="el-icon-question"></i>
      <p>帮助</p>
    </div>
    <div class="login-box">
      <img :src="aiImage" width="70px" />

      <h2>AI智能机器人</h2>
      <el-tabs v-model="loginType">
        <el-tab-pane style="height: 124px" label="登陆码登陆" name="1">
          <el-input
            class="m_input code_input"
            ref="inputCom"
            :class="{
              blur: !loginCode && inputBlur,
              focus: !inputBlur,
            }"
            name="phone"
            v-model.trim="loginCode"
            :disabled="loginLoading"
            placeholder="请输入登录码"
            @keyup.enter="submitLogin"
          ></el-input>
        </el-tab-pane>
        <el-tab-pane style="height: 124px" label="手机登陆" name="2">
          <el-input
            class="m_input"
            style="margin-bottom: 16px"
            placeholder="手机号"
            v-model="phone"
          >
          </el-input>
          <div></div>
          <el-input
            class="m_input"
            placeholder="请输入密码"
            v-model="password"
            name="password"
            show-password
            autocomplete="off"
          ></el-input>
          <div
            style="
              position: absolute;
              right: 0;
              font-size: 16px;
              line-height: 40px;
            "
          >
            <RouterLink to="/forgot" style="color: #409eff; font-weight: bold">
              忘记密码
            </RouterLink>
          </div>
          <!-- <el-input class="m_input sms_code" placeholder="验证码" v-model="smsCode">
            <template slot="append">
              <div style="color:#CACFD9;" v-show="countdown > 0">
                {{ countdown }}s
              </div>
              <div v-show="countdown <= 0" @click="getSmsCode">
                获取验证码
              </div>
            </template>
          </el-input> -->
        </el-tab-pane>
      </el-tabs>
      <div>
        <!--  placeholder="请输入登录码" -->
        <el-button
          type="primary"
          icon="el-icon-right"
          circle
          :loading="loginLoading"
          @click="submitLogin"
        ></el-button>
      </div>
      <div style="margin-top: 20px">
        <RouterLink to="/register" style="color: #409eff; font-weight: bold">
          注册
        </RouterLink>
      </div>
      <p class="checkbox-read">
        <el-checkbox v-model="checked"></el-checkbox>
        <span class="message">阅读并同意</span>
        <a
          style="color: rgb(116, 154, 227) !important; font-size: 12px"
          href="./agreement.html"
          target="_blank"
          rel="noreferrer"
          >《用户协议》
        </a>
        <a
          style="color: rgb(116, 154, 227) !important; font-size: 12px"
          href="./rule.html"
          target="_blank"
          rel="noreferrer"
        >
          《隐私政策》
        </a>
        <!-- <el-link
          type="primary"
          :underline="false"
          @click.stop.native="dialogVisible = true"
          >《用户协议》</el-link
        > -->
        <!-- <div class="course_link">
        <el-link href="https://ke.qq.com/course/5383436#term_id=105563755" target="_blank">
          赠送你一门大数据课程<br />
          <i style="transform: rotate(90deg);" class="el-icon-thumb"></i> 大数据应用部署与调优
        </el-link>
      </div> -->
      </p>
    </div>
    <div
      class=""
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        left: 0;
        bottom: 4px;
        width: 100%;
        text-align: center;
      "
    >
      <a
        style="color: rgb(116, 154, 227) !important; margin-right: 4px"
        href="https://beian.miit.gov.cn/"
        >苏ICP备11060547号-1</a
      >
      <img width="16" height="16" src="@/assets/images/ga.png" /><a
        style="color: rgb(116, 154, 227) !important"
        href="https://beian.mps.gov.cn/#/query/webSearch?code=32010402001528"
        rel="noreferrer"
        target="blank"
        >苏公网安备32010402001528号</a
      >
    </div>
    <el-dialog
      title="软件使用声明及免责声明"
      :visible.sync="dialogVisible"
      width="80%"
      custom-class="declaration"
    >
      <p>
        本免责及隐私保护声明（以下简称“隐私声明”或"本声明”），适用于本系统相关的系列软件，在您阅读本声明后若不同意此声明中的任何条款，或对本声明存任质疑，请立刻停止使用我们的软件。若您已经开始或正在使用本系统软件，则表示您已阅读并同意本声明的所有条款之约定。使用过程中务必准守国家互联网的法律法规。
      </p>
      <p>
        1、总则：您通过安装本系统软件提供的服务与功能即表示您已经同意与本系统建立同意本协议。本系统可随时执行全权决定更改“条款”。如“条款〞有任何变更，本系统官方网站上刊载公告作为通知。经修订的"条款”一经在官方网站上公布后，立即自动生效。
      </p>
      <p>2、本系统软件大多以推广为目的开发，应用在行业推广引流领域使用。</p>
      <p>
        3、一切因使用本系统软件而号!致之任何意外、疏忽合约毁坏、诽谤、版权或知识产权侵犯及其所造成的损失，本系统概不负责，与本系统开发服务商无关，亦不承担任何法律责任。
      </p>
      <p>
        4.用户使用本软件系统白行承担风险，我们作为系统开发商不做任何形式的保证,因网络状况、通讯线路等任何技术原因而导致用户不能正常使用，我们也不承担任何责任。
      </p>
      <p>
        5、本软件系统尊重并保护所有用户的个人隐私权，不会窃取任何用户计算机中的信息。
      </p>
      <p>
        6、任何单位或个人不得在没有本系统软件授权的情况下对软件进行二次开发、功能修改和反编译。
      </p>
      <p>
        7、我们提供的软件经营主体应接受合法、合规用途，不得利用本软件系统从事违法经营活动，我们仅作为软件开发商，对任何经营结果不做任何连带责任，软件使用过程中产生的任何法律后果我们概不负责，亦不承担任何法律责任。
      </p>
    </el-dialog>
  </div>
</template>
<script>
import {
  getToken,
  setToken,
  setLoginCode,
  removeLoginCode,
  getShowTip,
  setShowTip,
  setUserId,
  setFreeCount,
  removeFreeCount,
  removeUserId,
} from "@/utils/auth";
import aiImage from "@/assets/images/login.png";
import Typed from "typed.js";
import { login, phoneLogin, getSMS, getUserInfo } from "@/api/login.js";
import { RouterLink } from "vue-router";

export default {
  name: "Login",
  data() {
    return {
      loginLoading: false,
      loginCode: "",
      aiImage,
      checked: true,
      dialogVisible: false,
      placeholder: "请输入登录码",
      inputBlur: true,
      loginType: this.$route.query?.t || "1",
      phone: "",
      smsCode: "",
      countdown: 0,
      password: "",
    };
  },
  beforeRouteEnter(to, form, next) {
    if (getToken()) {
      next("/home");
    } else {
      next();
    }
  },
  mounted() {
    // console.dir(this.$refs.inputCom.$el.querySelector('input'))
    this.$refs.inputCom.$el.querySelector("input").onfocus = () => {
      this.inputBlur = false;
      console.log("onfocus");
    };
    this.$refs.inputCom.$el.querySelector("input").onblur = () => {
      console.log("onblur");
      this.inputBlur = true;
    };
    // new Typed(this.$refs.inputCom.$el.querySelector('input'), {
    //   strings: ['请输入登录码'],
    //   typeSpeed: 100,
    //   // backSpeed: 0,
    //   backDelay: 500,
    //   cursorChar: '_',
    //   showCursor: true,
    //   shuffle: true,
    //   smartBackspace: false,
    //   startDelay: 1000,
    //   attr: 'placeholder',
    //   bindInputFocusEvents: true,
    //   loop: true
    // });
  },
  methods: {
    countDownFn() {
      this.countdown = 60;
      this._setInt && clearInterval(this._setInt);
      this._setInt = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(this._setInt);
        }
      }, 1000);
    },
    getSmsCode() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^1[3-9][0-9]{9}$/.test(this.phone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      getSMS({
        phone: this.phone,
      }).then((res) => {
        if (res.data.code !== 200) {
          this.$toast(res.data.msg);
          return;
        }
        this.countDownFn();
      });
    },
    inputfocus() {
      console.log("inputfocus");
    },
    inputblur() {
      console.log("inputblur");
    },
    async submitLogin() {
      if (!this.checked) {
        // this.$message({
        //   message: "请先勾选用户协议",
        //   type: "warning",
        // });
        this.$toast("请先勾选用户协议");
        return false;
      }
      if (this.loginType == "1") {
        if (!this.loginCode) {
          // this.$message({
          //   message: "登录码不能为空",
          //   type: "warning",
          // });
          this.$toast("登录码不能为空");
          return false;
        }
        this.loginLoading = true;
        login({ key: this.loginCode })
          .then((res) => {
            console.log(res.data);
            let data = res.data;
            setLoginCode(this.loginCode);
            setToken(data.data);
            removeFreeCount();
            removeUserId();
            setShowTip("0");
            // setFreeCount(data.data.freeCount);
            // setUserId(data.data.userId);
            this.$nextTick(() => {
              // this.$router.push("/pay?renew=1");
              this.$router.push("/chat");
            });
          })
          .finally(() => {
            this.loginLoading = false;
          });
      } else if (this.loginType == "2") {
        if (!this.phone) {
          this.$toast("请输入手机号");
          return;
        }
        // if (!this.smsCode) {
        //   this.$toast('请输入手机验证码');
        //   return
        // }
        this.loginLoading = true;
        phoneLogin({
          phoneNumber: this.phone,
          password: this.password,
        })
          .then((res) => {
            const loginResult = res.data;
            if (loginResult.code != 200) {
              this.$toast(loginResult.msg);
              return;
            }
            removeLoginCode();
            setShowTip("0");
            setFreeCount(loginResult.data.freeCount);
            setUserId(loginResult.data.userId);
            getUserInfo({
              userId: loginResult.data.userId,
            }).then((res) => {
              const result = res.data;
              if (result.code == 200) {
                setToken(result.data || "");
                this.$router.push("/chat");
              } else if (result.code === 432 || result.code === 434) {
                Message({
                  message: "登录码失效",
                  type: "error",
                  duration: 3 * 1000,
                });
                // this.$router.push("/pay?renew=1");
              } else if (result.code == 602) {
                this.$router.push("/chat");
              } else {
              }
            });
          })
          .finally(() => {
            this.loginLoading = false;
          });
      } else {
        this.loginLoading = true;
      }
    },
  },
  components: { RouterLink },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #f6f7f9;
  overflow-y: auto;

  ::v-deep .el-tabs__nav {
    // display: flex;
    width: 100%;

    .el-tabs__item {
      width: 50% !important;
      font-size: 20px;
      color: #84878d;

      &.is-active {
        color: #5087f7;
      }
    }
  }

  .el-tabs__nav-wrap::after {
    background-color: #c8cbd2 !important;
  }

  .help {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ccc;
    font-size: 14px;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 24px;
    }
  }

  .course_link {
    margin-top: 20px;

    .el-link {
      font-size: 18px;
      color: #409eff;
      position: relative;

      text-decoration: underline;
      // &::after {
      //   content: '';
      //   position: absolute;
      //   left: 0;
      //   right: 0;
      //   height: 0;
      //   bottom: 0;
      //   border-bottom: 1px solid #409EFF;
      // }
    }
  }

  .login-box {
    // margin-top: -15%;
    text-align: center;
    padding: 0 20px;

    h2 {
      color: #5087f7;
      margin: 15px 30px 30px;
    }

    position: relative;
    ::v-deep .el-tabs__content {
      overflow: visible;
    }
    .m_input {
      width: 75vw;
      max-width: 320px;
      ::v-deep .el-input__inner {
        height: 46px;
        line-height: 46px;
      }
    }
    .code_input {
      ::v-deep .el-input__inner {
        text-align: center;
        height: 50px;
        line-height: 50px;
        &::-webkit-input-placeholder {
          text-align: center;
        }
      }
    }

    ::v-deep .el-input__inner {
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      // border-color: #409EFF;
      // outline: 0;

      outline: 0;
      border-color: #409eff;

      &::-webkit-input-placeholder {
        font-size: 24px;
      }
    }

    ::v-deep .sms_code {
      position: relative;

      .el-input-group__append {
        position: absolute;
        right: 1px;
        top: 1px;
        height: calc(100% - 4px);
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5087f7;
        background-color: transparent;
        cursor: pointer;
      }
    }

    ::v-deep .el-input {
      &.blur {
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 15px;
          top: 12px;
          height: 24px;
          width: 2px;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #333;
          animation: blink 1s infinite;
        }
      }

      &.focus {
        &::after {
          content: "";
          display: none;
        }
      }
    }

    ::v-deep .el-button {
      font-size: 50px;
      margin-top: 18px;
    }

    .checkbox-read {
      margin-top: 20px;

      ::v-deep .el-checkbox {
        vertical-align: middle;

        .el-checkbox__inner {
          width: 18px;
          height: 18px;
        }

        .el-checkbox__inner::after {
          height: 12px;
          left: 6px;
        }
      }

      .message {
        font-size: 15px;
        color: #cfd2db;
        margin-left: 10px;
      }
    }
  }
}

::v-deep .declaration {
  .el-dialog__body {
    padding: 5px 20px 30px;

    p {
      text-indent: 2em;
    }
  }
}
</style>
