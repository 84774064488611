<template>
  <div class="pay_detail">
    <div class="header">
      <div class="back" @click="$router.push('/chat')">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div>购买服务</div>
    </div>
    <div class="section">
      <p class="top-tips">
        购买的服务服务已下线！
        <!-- <template v-if="isRenew">
          <span>您购买的服务已过期<br />请续期服务！</span>
        </template>
        <template v-else> 您还没有服务权限，请先购买服务！ </template> -->
      </p>
      <!-- <div class="pay-card">
        <div class="title">商品信息</div>
        <div class="price">
          <b>¥{{ discountPrice }}</b> <span class="old">¥{{ price }}</span>
        </div>
        <div class="tip">
          一个手机号只能一个用户在线<br />
          有效期至{{ dayMonth }}
        </div>
        <div class="plist">
          <div class="pitem">
            <i class="el-icon-success"></i>AI智能聊天机器人
          </div>
          <div class="pitem"><i class="el-icon-success"></i>AI绘画</div>
        </div>
      </div>
      <template v-if="!hasUserPhone">
        <div class="paytype">
          <div class="title">请先注册账号，再进行支付</div>
          <el-button
            type="primary"
            :style="{
              width: '180px',
              height: '48px',
              fontSize: '22px',
            }"
            @click="$router.push('/register?from=pay')"
            >注册</el-button
          >
        </div>
      </template>
      <template v-else>
        <div class="paytype">
          <div class="title">请选择支付方式</div>
          <div class="paylist">
            <div
              class="payitem"
              :class="{
                active: payType === 'ZFB',
              }"
              @click="payTypeChange('ZFB')"
            >
              <div class="icon">
                <img src="@/assets/images/zfb.png" alt="" />
              </div>
              <div class="text">支付宝支付</div>
            </div>
            <div
              class="payitem"
              :class="{
                active: payType === 'WX',
                disable: deviceType == 'MOBILE',
              }"
              @click="payTypeChange('WX')"
            >
              <div class="icon">
                <img src="@/assets/images/wx.png" alt="" />
              </div>
              <div class="text">微信</div>
            </div>
          </div>
          <div class="payQrCode" v-show="deviceType == 'PC' && payType">
            <div class="head">
              <div>支付金额</div>
              <div class="price">￥{{ discountPrice }}</div>
            </div>
            <div class="qrCode" ref="qrCode" v-loading="qrCodeLoading"></div>
          </div>
        </div>
      </template> -->
    </div>
    <!-- <div class="mask" v-if="deviceType === 'WX'">
      <img class="ssi" src="@/assets/images/too.png" alt="" />
      <div class="info">
        <p>
          1. 点击右上角按钮
          <span style="vertical-align: text-top">
            <svg
              t="1676623118804"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="22546"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="28"
              height="28"
            >
              <path
                d="M341.333333 512a85.333333 85.333333 0 1 1-170.666666 0 85.333333 85.333333 0 0 1 170.666666 0z m256 0a85.333333 85.333333 0 1 1-170.666666 0 85.333333 85.333333 0 0 1 170.666666 0z m170.666667 85.333333a85.333333 85.333333 0 1 0 0-170.666666 85.333333 85.333333 0 0 0 0 170.666666z"
                fill="#ffffff"
                p-id="22547"
              ></path>
            </svg>
          </span>
        </p>
        <p>
          2. 选择
          <span>
            <svg
              t="1676622823641"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="21489"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="18"
              height="18"
            >
              <path
                d="M512 0C229.6832 0 0 229.6832 0 512s229.6832 512 512 512 512-229.6832 512-512S794.3168 0 512 0zM68.266667 512c0-107.008 38.075733-205.294933 101.393066-282.0096 6.638933 7.424 12.0832 17.220267 14.592 30.2592 5.341867 27.921067-14.199467 119.6032 10.666667 139.741867 37.956267 30.737067 96.699733 4.898133 126.941867 23.466666 30.583467 18.773333 106.8544 106.3424 136.635733 88.541867 50.039467-29.883733-35.754667-147.968-39.441067-179.899733-5.461333-47.325867 24.405333-102.792533 78.882134-82.6368 34.2016 12.6464 41.3184 58.231467 73.2672 60.1088 31.931733 1.877333 17.015467-98.423467 16.896-114.5856-0.341333-45.2608 44.253867-77.448533 91.716266-93.678934C841.506133 167.6288 955.733333 326.673067 955.733333 512c0 74.325333-18.466133 144.401067-50.909866 206.011733-3.720533-39.7312-13.380267-83.950933-37.563734-97.501866-46.9504-26.282667-99.208533-1.143467-137.1136-43.195734-15.6672-17.373867 4.0448-95.0272-43.144533-113.681066-91.733333-36.266667-166.058667 38.5536-188.603733 98.6624-9.4208 25.1392-12.6976 55.876267-8.226134 85.8624 0.9216 96.3584-48.930133 96.4096-30.020266 135.0656 20.718933 42.325333 94.788267 27.306667 80.366933 115.9168a59.050667 59.050667 0 0 1-17.988267 34.0992 557.226667 557.226667 0 0 1-26.282666 16.384 124.142933 124.142933 0 0 1-15.240534 4.898133C250.760533 938.564267 68.266667 746.257067 68.266667 512z"
                fill="#ffffff"
                p-id="21490"
              ></path>
            </svg>
          </span>
          在浏览器中打开
        </p>
      </div>
    </div> -->
  </div>
</template>
<script>
import { isMobile, getQueryObject, isWeiXin } from "@/utils";
import { payOrder, getPrice } from "@/api/pay";
import { getUserInfo } from "@/api/login";
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";
import loading from "vant/lib/loading";
import dayjs from "dayjs";
import {
  getToken,
  setToken,
  getUserId,
  setLoginCode,
  removeAllAuth,
} from "@/utils/auth";
export default {
  components: {},
  props: {},
  data() {
    return {
      deviceType: "", // PC,WX,MOBILE
      payType: "", // ZFB 支付宝 WX 微信
      isRenew: "0", // 是否是续期
      hasUserPhone: false, // 是否有手机号
      payInfo: {
        paytype: "5",
        ip: "127.0.0.1",
        quiturl: location.origin + location.pathname + "#/pay",
        returnurl: location.origin + location.pathname + "#/pay",

        // orderid: null,
        // paytype: "1",
        // ip: "192.168.0.221",
        // amount: null,
        // deductionIntegral: 0,
        // inviteCode: "",
        // payamount: null,
      },
      qrCodeLoading: false,
      price: 0,
      discountPrice: 0,
      termOfValidity: 0,
    };
  },
  computed: {
    dayMonth() {
      return dayjs().add(this.termOfValidity, "day").format("YYYY年MM月DD日");
    },
  },
  // beforeRouteEnter(to, form, next) {
  //   if (getToken() || getUserId()) {
  //     next();
  //   } else {
  //     removeAllAuth();
  //     next("/login");
  //   }
  // },
  watch: {},
  created() {
    this.isRenew = this.$route.query.renew == "1";
    console.log(getUserId());
    this.hasUserPhone = !!getUserId();
    if (this.hasUserPhone) {
      getUserInfo({
        userId: getUserId(),
      }).then((res) => {
        console.log(res);
        const result = res.data;
        if (result.code == 200) {
          if (result.data) {
            this.$toast("商品已支付");
            setLoginCode(result.data);
            setTimeout(() => {
              this.$router.replace("/chat");
            }, 2000);
          }
        } else {
        }
      });
      this.getLoginKey();
    }
    this.getIP().then((res) => {
      this.payInfo.ip = res;
    });

    this.getPrice();
    if (isMobile()) {
      if (isWeiXin()) {
        this.deviceType = "WX";
      } else {
        this.deviceType = "MOBILE";
      }
    } else {
      this.deviceType = "PC";
    }
    console.log(this.deviceType);
  },
  mounted() {},
  beforeDestroy() {
    this._setIntKey && clearTimeout(this._setIntKey);
  },
  methods: {
    getPrice() {
      getPrice().then((res) => {
        const result = res.data;
        if (result.code == 200) {
          this.price = result.data.price;
          this.discountPrice = result.data.discountPrice;
          this.termOfValidity = result.data.days;
        }
      });
    },
    getLoginKey() {
      this._setIntKey && clearTimeout(this._setIntKey);
      this._setIntKey = setTimeout(() => {
        getUserInfo({
          userId: getUserId(),
        })
          .then((res) => {
            const result = res.data;
            if (result.code == 200) {
              if (result.data) {
                this.$toast.success("支付成功！");
                setToken(result.data);
                setTimeout(() => {
                  this.$router.replace("/chat");
                }, 2000);
              }
              return;
            }
            this.getLoginKey();
          })
          .catch((err) => {
            this.getLoginKey();
          });
      }, 2000);
    },
    async getIP() {
      return await new Promise((resolve) => {
        fetch("https://api.ipify.org/?format=json")
          .then((res) => res.text())
          .then((res) => resolve(JSON.parse(res).ip));
      });
    },
    payTypeChange(val) {
      // console.log(val)
      if (this.deviceType == "MOBILE" && val === "WX") {
        return;
      }
      this.payType = val;
      this.payOrder();
    },
    payOrder() {
      let payParams = {};
      const { paytype, quiturl, returnurl, ...other } = this.payInfo;
      if (this.deviceType === "PC") {
        if (this.payType === "WX") {
          this.payInfo.paytype = "2";
          payParams = {
            paytype: this.payInfo.paytype,
            ip: this.payInfo.ip,
          };
        } else if (this.payType === "ZFB") {
          this.payInfo.paytype = "1";
          payParams = {
            ...this.payInfo,
          };
        }
      } else if (this.deviceType === "MOBILE") {
        if (this.payType === "WX") {
          this.payInfo.paytype = "5";
          payParams = {
            paytype: this.payInfo.paytype,
            ip: this.payInfo.ip,
          };
        } else if (this.payType === "ZFB") {
          this.payInfo.paytype = "3";
          payParams = {
            ...this.payInfo,
          };
        }
      }
      this.qrCodeLoading = true;
      payOrder({
        ...this.payInfo,
        token: getToken(),
        uid: getUserId(),
      })
        .then((res) => {
          const result = res.data;
          if (this.deviceType === "PC") {
            if (this.payType === "WX") {
              const packagep = result.packageP;
              this.createQrCode(packagep.code_url);
            } else if (this.payType === "ZFB") {
              const url = result.msg;
              this.createQrCode(url);
            }
          } else if (this.deviceType === "MOBILE") {
            if (this.payType === "WX") {
              const wxurl = result.url;
              // location.replace(wxurl);
              location.href = wxurl;
              // window.open(url);
              // this.$router.push({
              //   path: "/wxIfa",
              //   query: {
              //     url: wxurl,
              //   },
              // });
              // this.createQrCode(packagep.code_url);
            } else if (this.payType === "ZFB") {
              const formHtml = result.result;
              // this.createQrCode(formHtml);
              let html = formHtml;
              const div = document.createElement("div");
              div.innerHTML = html;
              document.body.appendChild(div);
              document.forms[0].submit();
              div.remove();
            }
          }
          // let orderMsg = JSON.parse(result.message);
        })
        .finally(() => {
          this.qrCodeLoading = false;
        });
    },

    createQrCode(url) {
      if (!this._qrcode) {
        this._qrcode = new QRCode(this.$refs.qrCode, {
          text: url,
          width: 128,
          height: 128,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      }
      this._qrcode.clear();
      this._qrcode.makeCode(url);
    },
    // createSocket(wsUrl) {
    //   const initWs = (wsUrl) => {
    //     this._socket && this._socket.close();
    //     this._socket = new WebSocket(wsUrl);
    //     this._socket.onmessage = (ev) => {
    //       try {
    //         let msgData = JSON.parse(ev.data);
    //         if (msgData.channel == "10" && msgData.msg == "success") {
    //           this._socket && this._socket.close();
    //           this.$message({
    //             message: "支付成功!!!",
    //             type: "success",
    //           });
    //           setTimeout(() => {
    //             this.$router.push("/chat");
    //           }, 500);
    //         }
    //       } catch {}
    //     };
    //     this._socket.onopen = () => {};
    //     this._socket.onerror = () => {
    //       setTimeout(() => {
    //         initWs(wsUrl);
    //       }, 1000);
    //     };
    //     this._socket.onclose = () => {};
    //   };
    //   initWs(wsUrl);
    // },
  },
};
</script>
<style lang="scss" scoped>
.pay_detail {
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  height: 100%;

  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    .ssi {
      position: absolute;
      right: 0;
      top: 10px;
      width: 60px;
      height: 80px;
    }
    .info {
      color: #fff;
      font-size: 20px;
      line-height: 48px;
    }
  }
  .header {
    height: 70px;
    background-color: #fff;
    position: relative;
    line-height: 70px;
    text-align: center;
    color: #7c7c7c;
    .back {
      font-size: 28px;
      font-weight: bolder;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      width: 70px;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
    }
    .edit {
      font-size: 24px;
      font-weight: bolder;
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      width: 70px;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
    }
  }

  .section {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
    .top-tips {
      color: #f66038;
      text-align: center;
      margin: 5vh 0;
    }

    .pay-card {
      background-color: #fff;
      background-image: url(@/assets/images/paybg.png);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 40%;
      padding: 18px 28px;
      width: 90%;
      margin: auto;
      border-radius: 10px;
      .title {
        color: #999999;
        font-size: 20px;
      }
      .price {
        color: #f66038;
        font-size: 36px;
        margin-top: 8px;
        .old {
          color: #999999;
          font-size: 20px;
          text-decoration: line-through;
        }
      }
      .tip {
        color: #5f5f5f;
      }

      .plist {
        color: #1c1c1c;
        font-size: 20px;
        font-weight: bold;
        margin-top: 18px;
        .el-icon-success {
          color: #65da4a;
          margin-right: 10px;
        }
        .pitem {
          margin-bottom: 8px;
          display: flex;
        }
      }
    }

    @media screen and (min-width: 728px) {
      .pay-card {
        background-size: 260px !important;
      }
    }
    .paytype {
      width: 90%;
      margin: auto;
      text-align: center;
      margin-top: 20px;
      .title {
        padding: 20px 0;
      }
    }
    .paylist {
      display: flex;
      justify-content: space-between;

      .payitem {
        width: 48%;
        background-color: #fff;
        padding: 18px;
        border-radius: 10px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        cursor: pointer;
        &.active {
          border-color: #1c81f5;
          color: #1c81f5;
        }
        &.disable {
          filter: grayscale(1);
        }
        .icon {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          text-align: left;
          margin-left: 8px;
          white-space: nowrap;
        }
      }
    }

    .payQrCode {
      display: flex;
      justify-content: center;
      background-color: #fff;
      border-radius: 10px;
      flex-direction: column;
      padding: 20px 20px 30px;
      margin-top: 20px;
      .head {
        color: #969696;
        .price {
          color: #f66038;
          font-size: 24px;
          font-weight: bold;
        }
        border-bottom: 1px solid #c8cbd2;
        margin-bottom: 20px;
        padding-bottom: 8px;
      }
      ::v-deep .qrCode {
        width: 70%;

        max-width: 300px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
