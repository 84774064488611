<template>
  <div class="home">
    <div class="home-header">
      <ul class="menu">
        <RouterLink tag="li" class="menu-item" to="/chat">
          <div class="menu-name">AI聊天</div>
          <div class="menu-circle"></div>
        </RouterLink>
        <!-- <RouterLink tag="li" class="menu-item" to="/expert">
          <div class="menu-name">AI专家</div>
          <div class="menu-circle"></div>
        </RouterLink>
        <RouterLink tag="li" class="menu-item" to="/drawImg">
          <div class="menu-name" style="position: relative">AI绘画</div>
          <div class="menu-circle"></div>
        </RouterLink> -->
      </ul>
    </div>
    <router-view class="home-content"></router-view>
  </div>
</template>

<script>
import { getToken, getUserId, removeAllAuth } from "@/utils/auth";
import { RouterLink } from "vue-router";
export default {
  name: "Home",
  components: { RouterLink },
  data() {
    return {};
  },
  beforeRouteEnter(to, form, next) {
    console.log(to.path);
    if (getToken() || getUserId() || to.path === "/pay") {
      next();
    } else {
      removeAllAuth();
      next("/login");
    }
  },
};
</script>
<style lang="scss" scoped>
$homeHader: 70px;
.home {
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  .home-header {
    height: $homeHader;
    font-size: 1.4em;
    font-weight: Bold;
    line-height: $homeHader;
    text-align: center;
    color: #5087f7;
    background: #fff;
    flex-shrink: 0;
    .menu {
      display: flex;
      list-style: none;
      justify-content: center;
      font-size: 24px;
      padding-right: 41px;
      .menu-item {
        margin: 0 20px;
        color: #7c7c7c;
        .menu-name {
          position: relative;
          z-index: 1;
          // background-color: #fff;
          // height: 24px;
        }
        &.router-link-exact-active {
          color: #1c81f5;
          position: relative;
          .menu-circle {
            position: absolute;
            height: 11px;
            width: 100%;
            overflow: hidden;
            bottom: 8px;
            &::before {
              content: "";

              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              border: 4px solid #20d2a6;
              bottom: 0;
              // z-index: -1;
              width: 18px;
              height: 18px;
              border-radius: 18px;
            }
          }
        }
      }
    }
  }
  .home-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // height: calc(100% - $homeHader);
    ::v-deep .chat-box {
      // height: calc(80vh - $homeHader);
      flex: 1;
      overflow-y: auto;
    }
  }
}
</style>
