<template>
  <div class="chat">
    <van-notice-bar
      mode="closeable"
      class="notice_bar"
      v-if="notice"
      left-icon="volume-o"
      :text="notice"
    />
    <div class="chat-box" ref="chatBox">
      <ul class="chat-content">
        <li
          v-for="item in textList"
          :key="item.id"
          class="chat__msg"
          :class="item.type === 1 ? 'chat_msg--right' : 'chat_msg--left'"
        >
          <!-- icon="el-icon-user" -->
          <el-avatar
            :size="40"
            :src="item.type === 1 ? oneselfImage : aiImage"
          ></el-avatar>
          <div class="msg_content">
            <div>{{ item.msg }}</div>
            <!--  v-if="item.type === 2 && item.done === false" -->
            <div
              class="stop"
              @click="stopMsg(item)"
              v-if="item.type === 2 && item.done === false"
            >
              <svg
                t="1676265002283"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2747"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18"
                height="18"
                style="fill: red"
              >
                <path
                  d="M512 128a382.6 382.6 0 1 1-149.45 30.15A381.54 381.54 0 0 1 512 128m0-64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z"
                  p-id="2748"
                ></path>
                <path d="M320 320l384 0 0 384-384 0 0-384Z" p-id="2749"></path>
              </svg>
            </div>
            <div
              v-if="
                item.type === 2 && (item.done == true || item.done == undefined)
              "
              class="copy"
              v-clipboard:copy="item.msg"
            >
              <i class="el-icon-document-copy"></i>
            </div>
            <div
              class="retry"
              @click="retryCopy(item)"
              v-if="item.type === 1 && item.err == true"
            >
              <svg
                t="1680175855696"
                style="margin-left: -2px"
                class="icon"
                viewBox="0 0 1033 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2926"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="16"
                height="16"
              >
                <path
                  d="M382.288284 458.497515H279.282367c18.177515-175.715976 175.715976-309.017751 351.431953-296.899408s309.017751 175.715976 296.899408 351.431952c-18.177515 175.715976-175.715976 309.017751-351.431953 296.899408-66.650888-6.059172-133.301775-36.35503-187.834319-78.76923-18.177515-12.118343-48.473373-12.118343-60.591716 6.059171v6.059172c-18.177515 30.295858 12.118343 60.591716 12.118343 60.591716 175.715976 151.47929 436.260355 127.242604 587.739645-42.414201s127.242604-436.260355-42.414201-587.739645-448.378698-133.301775-599.857989 42.414201c-54.532544 66.650888-90.887574 151.47929-96.946745 242.366864H79.329704l151.47929 224.189349 151.47929-224.189349z"
                  fill="#ffffff"
                  p-id="2927"
                ></path>
              </svg>
            </div>
          </div>
          <!-- item.type === 1 && item.status !== 'success' -->
          <p class="msg_success" v-if="false">
            <i class="el-icon-loading"></i>
          </p>
        </li>
      </ul>
    </div>
    <div class="chat-input">
      <div class="clear_history" @click="clearUserHistory">
        <i class="el-icon-delete"></i>清空记录
      </div>
      <div class="tips" v-show="tipShow">
        <div class="tips_content">请稍等，智能聊天机器人正在思考......</div>
      </div>
      <el-input
        ref="inputComp"
        type="textarea"
        resize="none"
        placeholder="请输入内容"
        v-model="value"
        @keyup.ctrl.enter.native="submitText"
      >
      </el-input>
      <el-button type="primary" class="send-text" @click="submitText">
        发送
      </el-button>
    </div>
  </div>
</template>

<script>
import aiImage from "../assets/images/ai.png";
import oneselfImage from "../assets/images/oneself.png";
import Vue from "vue";
import { Message } from "element-ui";
import {
  send,
  sse,
  seeChat,
  seeSend,
  seeSendNew,
  sendStreamByLimit,
  notice,
} from "@/api/home.js";
import VStorage from "@/utils/storage";
import {
  getToken,
  removeToken,
  getFreeCount,
  getUserId,
  removeAllAuth,
  setShowTip,
  getShowTip,
} from "@/utils/auth";
import { Utf8ArrayToStr } from "@/utils";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      aiImage,
      oneselfImage,
      value: "",
      tipShow: false,
      textList: [],
      notice: "",
    };
  },
  watch: {
    textList: {
      handler(val) {
        this.$nextTick(() => {
          this.$refs["chatBox"].scrollTop = this.$refs["chatBox"].scrollHeight;
        });
        //   scrollTo({
        //     bottom: 0,
        //     behavior: "smooth",
        //   });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const freeCount = getFreeCount();
    // console.log(freeCount);

    // setShowTip();
    // getShowTip();

    if (
      getShowTip() == "0" &&
      !getToken() &&
      freeCount !== undefined &&
      freeCount !== ""
    ) {
      if (freeCount > 0) {
        this.$dialog
          .confirm({
            title: "提示",
            message: `您的账号还没有购买服务<br />有<b>${getFreeCount()}</b>次的试用次数`,
            confirmButtonText: "去购买",
            cancelButtonText: "关闭",
            confirmButtonColor: "#5087f7",
          })
          .then(() => {
            this.$router.push("/pay");
          })
          .catch(() => {
            setShowTip("1");
          });
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: `当前账号已无试用次数，请您购买使用`,
            confirmButtonText: "去购买",
            cancelButtonText: "关闭",
            confirmButtonColor: "#5087f7",
          })
          .then(() => {
            this.$router.push("/pay");
          })
          .catch(() => {
            setShowTip("1");
          });
      }
    }

    // sse()
    //   .then((res) => res.body.getReader())
    //   .then((reader) => {
    //     read();
    //     function read() {
    //       reader.read().then(({ done, value }) => {
    //         // When no more data needs to be consumed, close the stream
    //         if (done) {
    //           // controller.close();
    //           return;
    //         }
    //         console.log(JSON.parse(Utf8ArrayToStr(value)));
    //         read();
    //       });
    //     }
    //     // return new ReadableStream({
    //     //   start(controller) {
    //     //     return pump();
    //     //     function pump() {
    //     //       return reader.read().then(({ done, value }) => {
    //     //         // When no more data needs to be consumed, close the stream
    //     //         if (done) {
    //     //           controller.close();
    //     //           return;
    //     //         }
    //     //         console.log(Utf8ArrayToStr(value));
    //     //         controller.enqueue(value);
    //     //         return pump();
    //     //       });
    //     //     }
    //     //   },
    //     // });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  beforeDestroy() {
    this._setInt && clearInterval(this._setInt);
  },
  created() {
    this._storage = new VStorage();
    this.textList = this._storage.getUser();
    this._setInt && clearInterval(this._setInt);
    this.getNotice();
    this._setInt = setInterval(() => {
      this.getNotice();
    }, 1000 * 45);
  },
  methods: {
    retryCopy(item) {
      this.value = item.msg;
      // console.log(this.$refs.inputComp.$el)
      this.$refs.inputComp.$el?.querySelector("textarea").focus();
    },
    getNotice() {
      notice().then((res) => {
        const result = res.data;
        if (result) {
          this.notice = result.content;
        } else {
          this.notice = "";
        }
      });
    },
    stopMsg(item) {
      // console.log(item.__ob__.abort);
      item.__ob__.abort && item.__ob__.abort();
      item.done = true;
      this._storage.setUser(this.textList.filter((d) => !d.err));
      // localStorage.setItem("history", JSON.stringify(this.textList));
    },
    clearUserHistory() {
      if (this._storage) {
        this._storage.deleteUser();
        this.textList = this._storage.getUser();
        this._storage.setUser(this.textList.filter((d) => !d.err));
        // localStorage.setItem("history", JSON.stringify(this.textList));
      }
    },
    submitText() {
      if (this.value) {
        let hist = this.textList.slice(-4, this.textList.length).map((item) => {
          // if (item.type === 1) {
          //   return "Human: " + item.msg;
          // }
          // return item.msg;
          return item;
        });
        const text = this.value;
        // console.log(hist);

        const meMsg = Vue.observable({ type: 1, msg: text, err: false });
        this.textList.push(meMsg);
        this.value = "";

        let strAll = "";
        this.tipShow = true;
        const controller = new AbortController();
        const { signal } = controller;
        let reqSend;
        if (getToken()) {
          const params = {
            text: text,
            token: getToken(),
          };
          if (hist.length) {
            // params.hist =
            params.hist = hist
              .map((item) => {
                // console.log(item);
                if (item.type === 1) {
                  return {
                    role: "user",
                    content: item.msg,
                  };
                }
                return {
                  role: "assistant",
                  content: item.msg ? item.msg.replace(/^AI:/, "") : "",
                };
              })
              .filter((d) => !!d.content);
          }
          // reqSend = seeSend(
          //   { text: text, hist: hist, token: getToken() },
          //   { signal }
          // );
          // 有loginKey
          reqSend = seeSendNew(params, { signal });
        } else {
          reqSend = sendStreamByLimit(
            { text: text, userId: getUserId() },
            { signal }
          );
        }
        // seeChat(text)
        reqSend
          .then((res) => {
            // console.log(res);
            return res.body.getReader();
          })
          .then((reader) => {
            // console.log(reader);
            const itemStr = Vue.observable({
              type: 2,
              msg: "",
              done: false,
            });
            itemStr.__ob__.abort = () => controller.abort();
            let onOff = true;
            this.tipShow = false;
            let prefixStr = "";
            // meMsg.err = true;
            const read = () => {
              reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  console.log("done");
                  // let ai = data.data.slice(data.data.indexOf("AI:"));
                  // this.textList.push({
                  //   type: 2,
                  //   msg: ai,
                  // });
                  itemStr.done = true;
                  // localStorage.setItem(
                  //   "history",
                  //   JSON.stringify(this.textList)
                  // );
                  this._storage.setUser(this.textList.filter((d) => !d.err));
                  return;
                }

                try {
                  // let resultStr = "";
                  // // console.log(value);
                  // for (let m = 0; m < value.length; m++)
                  //   resultStr += String.fromCharCode(value[m]);

                  let resultStr = Utf8ArrayToStr(value);
                  const aa = resultStr
                    .split(
                      `

`
                    )
                    .filter((m) => m !== "" && m !== "data: [DONE]")
                    .map((m) => {
                      try {
                        return JSON.parse(m.replace("data: ", ""));
                      } catch (error) {
                        console.warn("error2", m);
                        return "";
                      }
                    });
                  // console.log(aa);
                  if (aa && aa[0] && aa[0].code) {
                    const data = aa[0];
                    if (data.code >= 400 && data.code < 500) {
                      if (
                        data.code === 431 ||
                        data.code === 432 ||
                        data.code === 434
                      ) {
                        this.$router.push("/pay?renew=1");
                        return;
                      } else if (data.code === 433) {
                        Message({
                          message: data.msg,
                          type: "error",
                          duration: 3 * 1000,
                        });
                        removeAllAuth();
                        this.$router.push("/login");
                        return;
                      } else if (
                        data.code === 400 ||
                        data.code === 429 ||
                        data.code === 430
                      ) {
                        meMsg.err = true;
                        Message({
                          message: data.msg,
                          type: "error",
                          duration: 3 * 1000,
                        });

                        this._storage.setUser(
                          this.textList.filter((d) => !d.err)
                        );
                        return;
                      }
                      this.$router.push({
                        path: "/error",
                        query: {
                          status: 404,
                        },
                      });
                      return;
                    } else if (data.code >= 500 && data.code < 600) {
                      this.$router.push({
                        path: "/error",
                        query: {
                          status: 500,
                        },
                      });
                      return;
                    } else if (data.code >= 600 && data.code < 700) {
                      if (data.code == 603) {
                        this.$dialog
                          .confirm({
                            title: "提示",
                            message: "您的试用体验次数已使用完",
                            confirmButtonText: "去购买",
                            cancelButtonText: "关闭",
                            confirmButtonColor: "#5087f7",
                          })
                          .then(() => {
                            this.$router.push("/pay");
                          });
                        return;
                      }
                    }
                  } else {
                    aa.forEach((data) => {
                      //                     let resultStr = "";
                      //                     // console.log(value);
                      //                     for (let m = 0; m < value.length; m++)
                      //                       resultStr += String.fromCharCode(value[m]);
                      //                     //                 const str = resultStr
                      //                     //                   .replace(/[\r\n]/g, "")
                      //                     //                   .replace(/\n/g, "")
                      //                     //                   .replace(/\s/g, "")
                      //                     //                   .replace(/\t/g, "")
                      //                     //                   .replace(/^data:/i, "");

                      //                     console.log(resultStr);
                      //                     const aa = resultStr
                      //                       .split(
                      //                         `

                      // `
                      //                       )
                      //                       .filter((m) => m !== "" && m !== "data: [DONE]")
                      //                       .map((m) => {
                      //                         try {
                      //                           // console.log(m.replace("data: ", ""))
                      //                           return JSON.parse(m.replace("data: ", "")).choices[0]
                      //                             .text;
                      //                         } catch (error) {
                      //                           console.log("error", m);
                      //                           return "";
                      //                         }
                      //                       });
                      // console.log(data);
                      // try {
                      // console.log(JSON.parse(str));
                      // const obj = JSON.parse(str);
                      // strAll += aa.join("");
                      // try {
                      //   return JSON.parse(m.replace("data: ", "")).choices[0]
                      //     .text;
                      // } catch (error) {
                      //   console.log("error", m);
                      //   return "";
                      // }
                      if (data) {
                        // debugger
                        prefixStr = itemStr.msg || prefixStr;
                        prefixStr += data.choices
                          .map((itm) => itm.delta.content)
                          .join("");

                        // let oldMsg = itemStr.msg;
                        // itemStr.msg += data.choices
                        //   .map((itm) => itm.text)
                        //   .join("");
                        // console.log()
                        // itemStr.msg += JSON.parse(str).choices[0].text;
                        // oldMsg = oldMsg.replace(/^[\?？，,\r\n]/g, "");
                        // if (prefixStr.indexOf("AI:") !== -1) {
                        //   prefixStr = prefixStr.slice(prefixStr.indexOf("AI:"));
                        itemStr.msg = prefixStr;
                        // } else {
                        //   // console.log(prefixStr);
                        //   // prefixStr += prefixStr;
                        //   // oldMsg = "";
                        // }

                        // console.log(itemStr);
                        // console.log(prefixStr);
                        if (itemStr.msg && onOff) {
                          this.textList.push(itemStr);
                          onOff = false;
                        }
                        // if (prefixStr.indexOf("AI:") !== -1) {

                        // }

                        //             .then(({ data }) => {
                        // if (data.code >= 200 && data.code < 300) {
                        //   let ai = data.data.slice(data.data.indexOf("AI:"));
                      }

                      // itemStr.msg.
                      // obj.choices.reduce((a, b) => {
                      //   return a + b.text;
                      // }, "");
                      // console.log(strAll);
                      // } catch (error) {
                      //   // console.log(str, resultStr);
                      // }
                    });
                    read();
                  }
                } catch (error) {
                  console.warn("error1", error);
                }
              });
            };
            read();
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chat {
  width: 100vw;
  background-color: #f0f0f0;
  font-size: 18px;
  position: relative;

  .notice_bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 100;
  }
  .chat-box {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .chat-content {
      padding: 28px 14px;
      margin: 0;
      list-style: none;
      .chat__msg {
        display: flex;
        padding: 10px 0;
        // &.chat_msg--left {
        //   .msg_content {
        //     padding: 10px 20px 24px;
        //   }
        // }
        .msg_content {
          border-radius: 5px;
          font-size: 18px;
          line-height: 24px;
          padding: 10px 20px 10px;
          margin: 0 5px;
          white-space: pre-wrap;
          word-break: break-all;
          max-width: calc(100% - 100px);
          box-sizing: border-box;
          background-color: #fff;
          position: relative;
          // overflow: hidden;
          .copy,
          .stop {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: -40px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            // padding: 2px 4px;
            background-color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: #5087f7;
            }
          }
          .retry {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -40px;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            // padding: 2px 4px;
            background-color: #f9080e;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .stop {
            top: auto;
            bottom: 7px;
            margin: 0;
          }
        }
        .msg_success {
          height: 40px;
          line-height: 40px;
          i {
          }
        }
      }
      .chat_msg--right {
        flex-direction: row-reverse;
        .msg_content {
          background-color: #5087f7;
          color: #fff;
        }
      }
    }
  }
  .chat-input {
    width: 100%;
    height: 20vh;
    position: relative;
    box-sizing: border-box;
    ::v-deep .el-textarea {
      .el-textarea__inner {
        height: 20vh;
        //   background: #fcfcfc;
        border-radius: 0;
        font-size: 18px;
      }
    }
    ::v-deep .el-textarea__inner {
      padding: 10px 15px;
      box-sizing: border-box;
      // .{
      outline: 0;
      border-color: #409eff;
      // }
    }
    .clear_history {
      position: absolute;
      top: -30px;
      right: 4px;
      background-color: #fff;
      padding: 3px 4px 3px 6px;
      border-radius: 4px 0 0 0;
      font-size: 16px;
      color: #999;
      cursor: pointer;
      &:active {
        color: #5087f7;
      }
    }
    .tips {
      position: absolute;
      top: -30px;
      width: 100%;
      font-size: 16px;
      height: 24px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .tips_content {
        background-color: #fff;
        padding: 0 6px;
        border-radius: 4px;
      }
    }
    .send-text {
      position: absolute;
      bottom: 2vh;
      right: 2vw;
      width: 100px;
    }
  }
}
</style>
