import axios from "axios";
import { getToken, removeToken, removeAllAuth } from "@/utils/auth";
import { Message } from "element-ui";
import router from "@/router";

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 0,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    // if (getToken()) {
    //   config.headers["Authorization"] = "Bearer " + getToken();
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code || 200;
    /*
      431-登录码不存在，请检查验证码是否正确
      432-登录码失效，请联系客服
      433-登录过期，请退出后，重新登录
      434-登录码失效，请联系客服（无API_KEY错误）
      429-AI机器人CPU太热了，请稍后再试
      (让用户操作次数过多导致)
      430-AI机器人正在疯狂思考中，请稍后再试
      (服务繁忙导致)
      500-页面跑到找不到了，请联系客服
      (服务宕机，需要技术人员解决)
      501-AI机器人正在努力联网中. 请联系客服
      502-外部服务出错
      解决办法：找相关技术人员咨询情况
      503-网络好像有问题，请确认是否能正常上网!
    */
    if (code === 432 || code === 434) {
      // router.push("/pay?renew=1");
      Message({
        message: '登录码失效',
        type: "error",
        duration: 3 * 1000,
      });
      // console.log("res", res);
      return Promise.reject(res);
    } else if (code === 433) {
      // console.log(res);
      Message({
        message: res.data.msg,
        type: "error",
        duration: 3 * 1000,
      });
      // removeToken();
      removeAllAuth();
      // localStorage.removeItem("history");
      // location.href = "/";
      // console.log(router)
      router.push("/login");
      return Promise.reject(res);
    } else if (code === 431 || code === 429 || code === 430) {
      Message({
        message: res.data.msg,
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    console.log(error);
    Message({
      message: error.message,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
