import Vue from "vue";
import { getLoginCode, getUserId } from "@/utils/auth";
export default class VStorage {
  constructor() {
    this.history = this._getData();
    this.userKey = getLoginCode() || getUserId() || "unknow";
    if (Array.isArray(this.history)) {
      const olHhistory = this.history;
      this.history = {};
      this.history[this.userKey] = olHhistory;
      this._setData();
    } else {
      // if (this.history && this.history["unknow"]) {
      //   const olHhistory = this.history["unknow"] || [];
      //   this.history["unknow"] = [];
      //   delete this.history["unknow"];
      //   this.history[this.userKey] = olHhistory.concat(history);
      //   this._setData();
      // }
    }
  }
  setUser(data) {
    this.history[this.userKey] = data || [];
    this._setData();
  }
  getUser() {
    return this.history[this.userKey] || [];
  }
  deleteUser() {
    delete this.history[this.userKey];
    console.log(this.history);
    this._setData();
  }
  clear() {
    this.history = {};
    localStorage.removeItem("history");
  }
  _setData() {
    localStorage.setItem("history", JSON.stringify(this.history));
    this.history = this._getData();
  }
  _getData() {
    let storageHistory;
    try {
      storageHistory = localStorage.getItem("history")
        ? JSON.parse(localStorage.getItem("history"))
        : {};
    } catch (error) {
      storageHistory = {};
    }
    return storageHistory;
  }
}
