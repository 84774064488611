import service from "../utils/request";
import Qs from "qs";

export function payOrder(parms) {
  return service({
    url: "/api/pay/payOrder",
    method: "post",
    data: parms,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    // },
  });
}

export function getPrice(parms) {
  return service({
    url: "/api/pay/getPrice",
    method: "get",
  });
}


