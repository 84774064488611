<template>
  <div>
    <div class="text_to_image">
      <img :src="imgUrl" />
      <el-form ref="form" :model="form" label-width="80px" label-position="top">
        <el-form-item label="画面描述">
          <el-input
            ref="inputCom"
            :class="{
              blur: !form.text && inputBlur,
              focus: !inputBlur,
            }"
            class="textarea autofocus"
            type="textarea"
            placeholder="请输入尽可能详细、多个关键字用逗号拼接"
            v-model="form.text"
            style="font-size: 18px"
            :maxlength="300"
            show-word-limit
            :rows="5"
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div class="fixed_footer">
        <el-button class="photo_btn" @click="$router.push('/toImgList')">
          <svg
            class="icon"
            width="20px"
            height="20px"
            viewBox="0 0 1024 1024"
            style="fill: #fff; vertical-align: bottom"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M793.173333 191.658667c21.162667 0 38.4 17.237333 38.4 38.4v562.346666c0 21.162667-17.237333 38.4-38.4 38.4H230.826667a38.442667 38.442667 0 0 1-38.4-38.4V230.058667c0-21.162667 17.237333-38.4 38.4-38.4h562.346666m0-64H230.826667a102.4 102.4 0 0 0-102.4 102.4v562.346666a102.4 102.4 0 0 0 102.4 102.4h562.346666a102.4 102.4 0 0 0 102.4-102.4V230.058667a102.4 102.4 0 0 0-102.4-102.4z"
            />
            <path
              d="M384 319.274667c35.285333 0 64 28.714667 64 64s-28.714667 64-64 64-64-28.714667-64-64 28.714667-64 64-64m0-64a128 128 0 1 0 0 256 128 128 0 0 0 0-256zM811.093333 532.565333c6.826667 0 13.653333 0.256 20.48 0.768v233.472c0 35.285333-28.714667 64-64 64h-233.002666a277.674667 277.674667 0 0 1 276.522666-298.24m0-64c-188.16 0-341.333333 152.746667-341.333333 341.333334 0 29.44 3.84 57.6 10.666667 84.906666h287.146666c70.826667 0 128-57.173333 128-128v-287.573333c-26.88-6.826667-55.466667-10.666667-84.48-10.666667z"
            />
          </svg>
          画册
        </el-button>
        <!-- <el-button @click="submitImg">绘图</el-button> -->
        <el-button
          class="created_btn"
          :disabled="btnDisable"
          @click="submitCreate"
        >
          <template v-if="!btnDisable">
            <svg
              class="icon"
              width="20px"
              height="20px"
              viewBox="0 0 1024 1024"
              style="fill: #fff; vertical-align: bottom"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M550.912 961.536h-3.072c-72.704-1.024-125.952-52.224-145.408-138.24C368.64 671.744 307.2 677.888 222.208 687.104c-49.152 5.12-104.448 11.264-146.432-26.624-33.792-30.72-49.152-81.92-49.152-160.768C26.624 258.048 223.232 61.44 464.896 61.44c193.536 0 366.592 130.048 420.864 316.416 6.144 21.504-6.144 44.032-27.648 51.2-21.504 6.144-44.032-6.144-51.2-27.648-44.032-151.552-184.32-257.024-342.016-257.024-196.608 0-356.352 159.744-356.352 356.352 0 69.632 14.336 93.184 22.528 100.352 14.336 13.312 47.104 9.216 82.944 6.144 87.04-9.216 219.136-23.552 269.312 199.68 16.384 73.728 54.272 73.728 66.56 74.752h1.024C634.88 879.616 768 754.688 808.96 595.968c6.144-21.504 27.648-34.816 50.176-29.696 21.504 6.144 34.816 27.648 29.696 50.176-49.152 185.344-205.824 345.088-337.92 345.088z"
              />
              <path
                d="M240.64 458.752m-69.632 0a69.632 69.632 0 1 0 139.264 0 69.632 69.632 0 1 0-139.264 0Z"
              />
              <path
                d="M442.368 296.96m-52.224 0a52.224 52.224 0 1 0 104.448 0 52.224 52.224 0 1 0-104.448 0Z"
              />
              <path
                d="M658.432 366.592m-43.008 0a43.008 43.008 0 1 0 86.016 0 43.008 43.008 0 1 0-86.016 0Z"
              />
              <path
                d="M518.144 654.336c-17.408 0-32.768-10.24-38.912-27.648-7.168-21.504 4.096-45.056 25.6-52.224L946.176 419.84c21.504-7.168 45.056 4.096 52.224 25.6 7.168 21.504-4.096 45.056-25.6 52.224L531.456 652.288c-5.12 1.024-9.216 2.048-13.312 2.048z"
              />
            </svg>
            开始绘画
          </template>
          <template v-else> 正在绘图中.... </template>
        </el-button>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        custom-class="img_dialog"
      >
        <div slot="title"></div>
        <div class="img" v-loading="loading">
          <el-image
            v-if="image"
            style="width: 100%; height: 100%"
            :src="image"
            fit="contain"
          ></el-image>
        </div>
        <!-- <el-progress v-if="loading" :percentage="percentage"></el-progress> -->
        <template v-if="loading">
          <div v-html="progress"></div>
        </template>
        <template v-else>
          <div style="display: flex; justify-content: center; margin-top: 10px">
            <el-button
              class=""
              style="
                background-color: #313131;
                color: #fff;
                border: 0;
                width: 40%;
              "
              @click="dialogVisible = false"
            >
              取消
            </el-button>
            <el-button
              class=""
              style="
                background-color: #5087f7;
                color: #fff;
                border: 0;
                width: 40%;
              "
              @click="downloadImg"
            >
              下载
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  textToNewImage,
  textToNewImgList,
  textToImage,
  textToImageSpeed,
  textToImageWaitNum,
  getUpdate,
} from "@/api/home";

import { getToken, getLoginCode } from "@/utils/auth";
import { Message } from "element-ui";

export default {
  components: {},
  props: {},
  data() {
    return {
      imgUrl: "",
      dialogVisible: false,
      loading: false,
      image: "",
      waitNum: 0,
      percentage: 0,
      progress: "",
      exp: "",
      currentExpIndex: "",
      inputBlur: true,
      exampleList: [
        {
          name: "海底世界",
          value:
            "一幅美丽的写实画，描绘了一只浅绿色海龟在红橙色珊瑚礁上游泳，穿过蓝紫色水域，三色调色板，以国家地理的风格绘制",
        },
        {
          name: "古风建筑",
          value:
            "一幅美丽的中国仙境画，充满了桃花树和中国古建筑，多云多雾，素描",
        },
        {
          name: "岛上樱花",
          value:
            "一幅美丽的画，广阔的视野，在岛上，樱花，平静的湖面，早晨，光效果",
        },
        {
          name: "水墨红梅",
          value: "一幅美丽的画，广角，下雪天气，红梅，冬天 ，水墨画，有山有水",
        },
      ],
      typeList: [
        {
          url: "./images/text2img/iimg2.png",
          value: "",
          name: "不限",
        },
        {
          url: "./images/text2img/iimg3.png",
          value: "动漫",
          name: "动漫",
        },
        {
          url: "./images/text2img/iimg4.png",
          value: "写实照片",
          name: "写实照片",
        },
        {
          url: "./images/text2img/iimg5.png",
          value: "素描",
          name: "素描",
        },
        {
          url: "./images/text2img/iimg6.png",
          value: "3D",
          name: "3D",
        },
        {
          url: "./images/text2img/iimg7.png",
          value: "中国画",
          name: "中国画",
        },
        {
          url: "./images/text2img/iimg8.png",
          value: "油画",
          name: "油画",
        },
        {
          url: "./images/text2img/iimg1.png",
          value: "赛博朋克",
          name: "赛博朋克",
        },
      ],
      scaleList: [
        {
          name: "1:1",
          className: "v1_1",
          width: 512,
          height: 512,
        },
        {
          name: "4:3",
          className: "v4_3",
          width: 832,
          height: 640,
        },
        {
          name: "3:4",
          className: "v3_4",
          width: 640,
          height: 832,
        },
        {
          name: "16:9",
          className: "v16_9",
          width: 1344,
          height: 768,
        },
        {
          name: "9:16",
          className: "v9_16",
          width: 768,
          height: 1344,
        },
      ],
      form: {
        width: 512,
        height: 512,
        text: "",
        style: "",
        scaleIndex: 0,
      },
      btnDisable: false,
    };
  },
  computed: {
    baseImgUrl() {
      return process.env.VUE_APP_BASE_IMG_URL;
    },
  },
  watch: {
    "form.scaleIndex"(index) {
      this.form.width = this.scaleList[index].width;
      this.form.height = this.scaleList[index].height;
    },
  },
  async created() {
    // this.getTextToImageWaitNum();
    // this._setInt = setInterval(() => {
    //   this.getTextToImageWaitNum();
    // }, 3000);
    await this.update();
    this._setIntU && clearInterval(this._setIntU);
    this._setIntU = setInterval(() => {
      this.update();
    }, 4000);
  },
  mounted() {
    // this.progress = `<span id='txt2img_progress_span' style='display: none'>1675866257.3819592</span><p><div class='progressDiv'><div class='progress' style="overflow:visible;width:50.0%;white-space:nowrap;">&nbsp;&nbsp;50% ETA: 01s</div></div></p>`;
  },
  mounted() {
    this.$refs.inputCom.$el.querySelector("textarea").onfocus = () => {
      this.inputBlur = false;
    };
    this.$refs.inputCom.$el.querySelector("textarea").onblur = () => {
      this.inputBlur = true;
    };
  },
  beforeDestroy() {
    this._setInt && clearInterval(this._setInt);
    this._setIntU && clearInterval(this._setIntU);
    this._setIntSpeed && clearInterval(this._setIntSpeed);
  },
  methods: {
    submitImg() {
      const { scaleIndex, ...params } = this.form;
      textToNewImage({
        token: getToken(),
        text: "一个湖面，湖面很平静，水清清的，高空的白云和周围的山峰清晰地倒映在水中",
      }).then((res) => {
        this.imgUrl = "data:image/jpg;base64," + res.data.data;
        console.log(555555555555, res, this.imgUrl);
      });
    },
    changeExp(index, item) {
      if (this.currentExpIndex == String(index)) {
        this.currentExpIndex = "";
        this.form.text = "";
      } else {
        this.currentExpIndex = String(index);
        this.form.text = item.value;
      }
    },
    async update() {
      await getUpdate({
        token: getToken(),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.data) {
            if (res.data.length) {
              this.btnDisable = res.data.some(
                (item) => item.status == 0 || item.status == 3
              );
            } else {
              this.btnDisable = false;
            }
          } else {
          }
        });
    },
    getTextToImageWaitNum() {
      textToImageWaitNum().then((res) => {
        this.waitNum = parseInt(res.data.data);
      });
    },
    getTextToImageSpeed() {
      this._setIntSpeed && clearInterval(this._setIntSpeed);
      textToImageSpeed({ token: getToken() }).then((res) => {
        if (res.data?.data?.data?.[0]) {
          this.progress = res.data.data.data[0];
          console.log(this.progress);
        }
        if (this.loading) {
          this.getTextToImageSpeed();
        }
      });
    },
    submitCreate() {
      const { scaleIndex, ...params } = this.form;

      if (!params.text) {
        this.$toast("请输入画面描述");

        return;
      }
      if (!getToken()) {
        this.$router.push("/pay");
        return;
      }
      // this.image = "";
      this.loading = true;
      // this.dialogVisible = true;
      textToImage({
        ...params,
        token: getToken(),
      })
        .then((res) => {
          // console.log(res);
          const data = res.data;
          if (data.code == 200) {
            this.$router.push("/toImgList");
          } else {
            Message({
              message: data.msg,
              type: "error",
              duration: 3 * 1000,
            });
          }
          return;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadImg() {
      this.downLoadImage(this.image);
    },
    downLoadImage(url) {
      let x = new XMLHttpRequest();
      let resourceUrl = url;
      let name = url.split("/").slice(-1)[0].split(".")[0];

      x.open("GET", resourceUrl, true);
      x.responseType = "blob";
      x.onload = (e) => {
        // ie10+
        if (navigator.msSaveBlob) {
          let name = resourceUrl.substr(resourceUrl.lastIndexOf("/") + 1);
          return navigator.msSaveBlob(x.response, name);
        } else {
          let url = window.URL.createObjectURL(x.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = name; //保存的文件名称
          a.click();
        }
      };
      x.send();
    },
  },
};
</script>
<style lang="scss" scoped>
.text_to_image {
  padding: 10px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 66px;
  width: 100%;
  height: 100%;

  .example_list {
    display: flex;
    margin: -6px;
    padding-bottom: 20px;
    .example_item {
      background-color: #fff;
      flex: 1;
      max-width: 160px;
      margin: 6px;
      font-size: 14px;
      line-height: 36px;
      height: 36px;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
      &.active {
        background-color: #1c81f5;
        color: #fff;
      }
    }
  }
  ::v-deep .textarea {
    .el-textarea__inner {
      padding: 15px;
    }
  }
  ::v-deep .img_dialog {
    width: 80%;
    max-width: 400px;
    height: 80%;
    max-width: 800px;
    background: transparent !important;
    box-shadow: none;
    margin-top: 10vh !important;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      .img {
        height: 100%;
        background-color: #f1f1f1;
        max-height: 80vh;
      }
      .progressDiv {
        width: 100%;
        margin-top: 10px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        .progress {
          background-color: #5087f7;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  .el-form-item {
    ::v-deep .el-form-item__label {
      // font-weight: bold;
      font-size: 22px;
      color: #7c7c7c;
      display: block;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      ::v-deep .el-form-item__label {
        font-size: 18px;
        line-height: 28px;
      }
    }
    ::v-deep .el-textarea {
      position: relative;
      &.blur {
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 15px;
          top: 18px;
          height: 24px;
          width: 2px;
          // margin: auto;
          background-color: #333;
          animation: blink 1s infinite;
        }
      }
      &.focus {
        &::after {
          content: "";
          display: none;
        }
      }
    }
    .el-radio-group {
      display: flex;
    }
    ::v-deep .el-radio {
      height: auto;
      padding: 0;
      margin-right: 0;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
      }
    }

    ::v-deep .style__list {
      display: flex;
      flex-wrap: wrap;
      margin: -8px;
    }
    ::v-deep .style_setting {
      padding: 0;
      display: block;
      max-width: 90px;
      border-radius: 4px;
      overflow: hidden;
      margin: 8px !important;
      // margin: 0 !important;
      border: 0;
      position: relative;

      .el-radio__label {
        padding: 0;
        display: block;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        .el-image {
          display: block;
        }
        .text {
          position: absolute;
          z-index: 1;
          bottom: 0;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.4);
          width: 100%;
          line-height: 24px;
          color: #fff;
          border-radius: 0 0 4px 4px;
        }
      }
      &.is-checked {
        .el-radio__label {
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: 2px solid #066ada;
            border-radius: 5px;
            z-index: 10;
          }
          .text {
            background-color: #066ada;
            color: #fff;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      ::v-deep .style__list {
        margin: 0;
      }
      ::v-deep .style_setting {
        width: 25%;
        max-width: 25%;
        padding: 4px;
        margin: 0 !important;
      }
    }

    ::v-deep .scale_setting {
      width: 120px;
      height: 120px;

      // max-width: 120px;
      // max-height: 120px;
      background-color: #fff;
      &.is-checked {
        .block {
          .block_inner {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            border: 6px solid #066ada;
            background-color: #1c81f5;
          }
        }
      }
      .el-radio__label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        flex-direction: column;
        // padding-top: 4px;
      }
      .block__wrap {
        flex: 1;
        width: 100%;
        position: relative;
      }
      .block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 0;
        &.v1_1 {
          width: 50%;
          padding-bottom: 50%;
        }
        &.v4_3 {
          width: 60%;
          padding-bottom: 46%;
        }
        &.v3_4 {
          width: 46%;
          padding-bottom: 60%;
        }
        &.v16_9 {
          width: 60%;
          padding-bottom: 36%;
        }
        &.v9_16 {
          width: 36%;
          padding-bottom: 60%;
        }
        .block_inner {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          border: 6px solid #c8c8c8;
          background-color: #d6d6d6;
        }
      }

      .txt {
        // margin-top: 8px;
        height: 20px;
        width: 100%;
        text-align: center;
      }
    }

    @media screen and (max-width: 500px) {
      ::v-deep .scale_setting {
        height: 80px;
      }
    }
  }

  .fixed_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .photo_btn {
      width: 30%;
      background-color: #2ebd9a;
      color: #fff;
      margin-bottom: 10px;
      font-size: 18px;
      height: 56px;
    }
    .created_btn {
      width: 66%;
      background-color: #5087f7;
      color: #fff;
      margin-bottom: 10px;
      font-size: 18px;
      height: 56px;
      &.is-disabled {
        background-color: #949494;
      }
    }
  }
}
</style>
