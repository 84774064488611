import service from "../utils/request";
import axios from "axios";
export function login(data) {
  return service({
    url: "/api/openai/login",
    method: "post",
    data,
  });
}

export function phoneLogin(data) {
  return service({
    url: "/api/userLogin/userLogin",
    method: "post",
    params: data,
  });
}


export function updatePassword(data) {
  return service({
    url: "/api/userLogin/updatePassword",
    method: "post",
    params: data,
  });
}

export function userRegister(data) {
  return service({
    url: "/api/userLogin/userRegister",
    method: "post",
    params: data,
  });
}

export function getSMS(data) {
  return service({
    url: "/api/userLogin/getSMS",
    method: "post",
    params: data,
  });
}


export function getUserInfo(data) {
  return axios({
    baseURL: process.env.VUE_APP_BASE_API,
    url: "/api/userLogin/getLoginKeyByUserId",
    method: "post",
    params: data,
  });
}
